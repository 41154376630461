import React, { ReactElement } from 'react';
import { ReplaysProvider } from './parts/replays.provider';
import { ReplaysComponent } from './parts/replays.component';

export function ReplaysStandalone(): ReactElement {
  return (
    <ReplaysProvider>
      <ReplaysComponent />
    </ReplaysProvider>
  );
}
