import {BehaviorSubject, combineLatest, map, tap} from 'rxjs';
import {v4 as uuid} from 'uuid';
import {array$$, nullable$$} from '../../../helpers/observable.helper';
import {IStore} from '../../../types/interfaces/store.interface';
import {interop} from '../../../interop';
import {l$} from "../../../locale";
import moment from "moment";
import _ from "lodash";

export class ReplaysStore implements IStore {
  private dataset$ = interop.replays$.pipe(
    map((replays) => {
      return replays.map((el) => {
        return {
          id: el.Name,
          columns: [
            {id: "replayName", data: el.Name},
            {id: "map", data: el.MapId},
            {id: "playerCount", data: el.Players.length},
            // {id: "aiPlayers", data: el.Players.filter(_player => !!_player.IsArtificial).length},
            {id: "teamCount", data: Object.keys(_.groupBy(el.Players, 'Team')).length},
            {id: "dateRecorded", data: el.DateRecorded},
            {id: "duration", data: moment.utc(moment.duration(el.Duration, 'seconds').asMilliseconds()).format("mm:ss")},
            {id: "gameVersion", data: el.GameVersion},
            {id: "trash", data: "✖"},
          ],
        };
      });
    })
  );

  private headers$ = l$.pipe(
    map((l) =>
      [
        "Replay Name",
        "Map",
        "Players",
        "Teams",
        // "AI Players",
        "Date Recorded",
        "Duration",
        "Game Version",
        "",
      ].map((el) => ({
        id: uuid(),
        data: el,
      }))
    )
  );

  data$$ = nullable$$(
    combineLatest([this.headers$, this.dataset$]).pipe(
      map(([headers, sortedDataset]) => ({
        headers,
        sortedDataset,
      }))
    )
  );

  reset = (): void => {
  };
}
