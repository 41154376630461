import React, { ReactElement, useEffect } from 'react';
import { SettingsOptionComponent } from '../../../components/settings-option.component';
import { useObservable } from '../../../helpers/useObservable';
import { ControlTypeEnum } from '../../../types/enums/control-type.enum';
import { interop } from '../../../interop';

export function AudioSettingsComponent(): ReactElement {
  const audioSettings = useObservable(interop.audioSettings$$);

  function setMenuMusic(val: number): void {
    if (audioSettings) {
      interop.changeAudioSettings({ ...audioSettings, MainMenuMusicVolume: val / 100 });
    }
  }
  function setMenuAmbience(val: number): void {
    if (audioSettings) {
      interop.changeAudioSettings({ ...audioSettings, MainMenuAmbienceVolume: val / 100 });
    }
  }
  function setGameMusic(val: number): void {
    if (audioSettings) {
      interop.changeAudioSettings({ ...audioSettings, InGameMusicVolume: val / 100 });
    }
  }

  return audioSettings ? (
    <>
      <div className="col-span-12 grid grid-cols-12 mx-10 mt-5 bg-gray-800 mx-12 bg-opacity-30">
        <div className="col-span-12 grid grid-cols-12 bg-gray-700 bg-opacity-0 m-3 gap-3">
          <SettingsOptionComponent
            min={0}
            max={100}
            controlType={ControlTypeEnum.Slider}
            setState={setMenuMusic}
            state={audioSettings.MainMenuMusicVolume * 100}>
            Main Menu Music
          </SettingsOptionComponent>
          <SettingsOptionComponent
            min={0}
            max={100}
            controlType={ControlTypeEnum.Slider}
            setState={setMenuAmbience}
            state={audioSettings.MainMenuAmbienceVolume * 100}>
            Main Menu Ambience
          </SettingsOptionComponent>
          <SettingsOptionComponent
            min={0}
            max={100}
            controlType={ControlTypeEnum.Slider}
            setState={setGameMusic}
            state={audioSettings.InGameMusicVolume * 100}>
            In-Game Music
          </SettingsOptionComponent>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
}
