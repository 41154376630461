import React from "react";
import {EDamageKind} from "../types/enums/damage-kind.enum";
import {variousHelper} from "../helpers/various.helper";

function DamageIcon(props: { damageFactor?: number, damageKind: EDamageKind, paddingTop: number }) {
  return props.damageFactor == undefined || props.damageFactor >= 0.27 ? (<div className={`h-4 w-4 ${variousHelper.iconFromDamageKind(props.damageKind)}-icon-mask-image`} style={{
    backgroundColor: `hsl(${props.damageFactor == undefined ? 90 : 90 - props.damageFactor * 90} 100% 50%)`, paddingTop: `${props.paddingTop}rem`
  }}>
  </div>) : <></>;
}

export default DamageIcon;
