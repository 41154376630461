import {
  BehaviorSubject,
  combineLatest,
  delay,
  distinctUntilChanged, EMPTY, first, interval,
  map,
  mergeMap, Observable,
  pairwise,
  tap
} from 'rxjs';
import { bool$$ } from './helpers/observable.helper';
import { interop } from './interop';

class GlobalStore {
  setIsLoading($: Observable<any>): void {
    this.loadingSubject.next(true);
    $.pipe(first()).subscribe(() => this.loadingSubject.next(false));
  }

  private loadingSubject = new BehaviorSubject(false);

  loading$ = this.loadingSubject.asObservable();
  loading$$ = bool$$(this.loading$);

  delayedLoading$ = this.loading$.pipe(delay(5000));
  delayedLoading$$ = bool$$(this.delayedLoading$);

  private lobbyMemberCount$ = interop.lobby$.pipe(
    map((el) => el?.Members.length || 0),
    distinctUntilChanged()
  );
  private delayedMemberCount$ = this.lobbyMemberCount$.pipe(delay(500));

  lobbyMembersReady$ = interop.lobby$.pipe(
    map((lobby) =>
      lobby != undefined &&
      lobby.Members.length > 1 &&
      !lobby.Members.find((el) => !el.IsReady)
    )
  );
  lobbyMembersReady$$ = bool$$(this.lobbyMembersReady$);

  // lobbyMembersReadyButYouAreNot$ =

  /**
   * @description Is true for 500 ms whenever count changed
   */
  lobbyMemberCountChangedTimed$ = combineLatest([
    this.lobbyMemberCount$,
    this.delayedMemberCount$
  ]).pipe(
    map(([countA, countB]) => countA !== countB),
    distinctUntilChanged()
  );

  /**
   * @description Is true for 500 ms whenever count changed
   */
  lobbyMemberCountChangedTimed$$ = bool$$(this.lobbyMemberCountChangedTimed$);

  /**
   * @description Is negative number if member count decremented
   * Is positive number if member count incremented
   */
  lobbyMemberCountChange$ = this.lobbyMemberCount$.pipe(
    pairwise(),
    map(([a, b]) => a - b)
  );

  doCountSubject = new BehaviorSubject<boolean>(false);

  startTimer(): void {
    this.doCountSubject.next(true);
  }

  stopTimer(): void {
    this.doCountSubject.next(false);
  }

  adjustedSearchTime$ = this.doCountSubject.pipe(
    mergeMap((doCount) => (doCount ? interval(1000).pipe(
      mergeMap(ms => this.lobbyMemberCountChange$.pipe(map(change => {
          if (change) {
            return 0;
          } else {
            return ms + 5000;
          }
        }))
      )) : EMPTY))
  );

  lookForLobbyWithMoreMembers$ = this.adjustedSearchTime$.pipe(
    map((val) => val > 10000),
    distinctUntilChanged()
  );

  lookForLobbyWithMoreMembers$$ = bool$$(this.lookForLobbyWithMoreMembers$);
}

export const globalStore = new GlobalStore();
