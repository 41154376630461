import AssemblyContainer from "./assembly-container.component";
import AssemblySideButton from "./assembly-side-button.component";
import React from "react";
import {IWeapon} from "../types/interfaces/weapon.interface";
import ValueBarComponent from "./value-bar.component";
import DamageIcon from "./damage-icon.component";
import {IHoverpad} from "../types/interfaces/hoverpad.interface";
import {ESpecialization} from "../types/enums/specialization.enum";
import SpecializationIcon from "./specialization-icon.component";

function AssemblyUnitPropertyComponent(props: { title: string, currentItemTitle: string, onNavigateLeft: () => void, onNavigateRight: () => void, weapon?: IWeapon, hoverpad?: IHoverpad, description?: string, specialization?: ESpecialization }) {
  const validWeapon = props.weapon && (props.weapon?.baseRange || 0) > 0;
  const validHoverpad = props.hoverpad && (props.hoverpad?.SpeedFactor || 0) > 0;

  return <>
    <AssemblyContainer>
      <div className="col-span-12 grid grid-cols-12 gap-0 border-2 border-opacity-0 border-neutral-600">
        <div
          className="col-span-12 flex justify-center content-center h-12 w-full items-center p-0 shadow-md border-neutral-900 dark:shadow-white/20 bg-opacity-95 bg-cyan-900">
          <h2 className="m-0 text-center text-xl text-neutral-100">{props.title}</h2>
        </div>
      </div>
    </AssemblyContainer>
    <AssemblyContainer>
      <div
        className="col-span-12 grid grid-cols-12 gap-0 border-2 border-opacity-0 border-neutral-600">
        <AssemblySideButton height={8} onClick={props.onNavigateLeft} direction={-1}/>

        <div
             className="col-span-10 flex flex-col justify-center content-center h-32 w-full p-2 shadow-md border-neutral-900 dark:shadow-white/20 bg-opacity-95 bg-cyan-900">
          <div className="relative flex justify-center space-x-2">
            {/*{props.weapon && valid ? <DamageIcon damageKind={props.weapon.DamageKind}/> : <></>}*/}
            <h2 className="m-0 text-center text-xl text-neutral-100">{props.currentItemTitle} </h2>
            {/*{props.weapon && valid ? <DamageIcon damageKind={props.weapon.DamageKind}/> : <></>}*/}
            {props.specialization != undefined && props.specialization != ESpecialization.None ?
              <div className="absolute left-0 text-blue-300">
                <SpecializationIcon specialization={props.specialization} paddingTop={2}/>
              </div>
              : <></>
            }
          </div>


          {props.description ?
            <p className="w-full text-center text-white text-opacity-95 mb-0 h-32">{props.description.substring(0, props.description.length - 1)}</p>
            : <></>}

          {props.hoverpad && validHoverpad ? (
            <>
              <div className="flex justify-center space-x-2">
                <p className="w-full text-left text-white text-opacity-95 mb-0" style={{fontFamily: 'Astronomus-Italic'}}>Speed: {props.hoverpad.SpeedFactor}x</p>
                <ValueBarComponent baseValue={props.hoverpad.SpeedFactor - 1} barLengthMultiplier={70} color={"green"} hideValue={true}/>
              </div>
              <div className="flex justify-center space-x-2">
                <p className="w-full text-left text-white text-opacity-95 mb-0" style={{fontFamily: 'Astronomus-Italic'}}>Cost: <span style={{fontFamily: "Astronomus-Regular"}}>${props.hoverpad.Cost}</span></p>
                {/*<ValueBarComponent value={props.hoverpad.SpeedFactor - 1} barLengthMultiplier={70} color={"green"} hideValue={true}/>*/}
              </div>
            </>
            )
            :<></>
          }

          {props.weapon && validWeapon ? (
            <>
              {/*<div className="flex space-x-1 p-0 m-0 justify-center">*/}
              {/*  <DamageIcon damageKind={props.weapon.DamageKind}/>*/}
              {/*</div>*/}
              <div className="flex justify-center space-x-2 mt-1 mb-1">
                <div className="flex justify-center space-x-2">
                  <p className="text-left text-white text-opacity-95 mb-0" style={{fontFamily: 'Astronomus-Italic'}}>Type:</p>
                  <div className="flex items-center space-x-2 w-full" style={{marginTop: "0rem", marginBottom: "-0rem"}}>
                    <DamageIcon damageKind={props.weapon.DamageKind} paddingTop={0}/>
                  </div>
                </div>
                <div className="flex justify-center space-x-2">
                  <p className="text-left text-white text-opacity-95 mb-0" style={{fontFamily: 'Astronomus-Italic'}}>Cost: <span style={{fontFamily: "Astronomus-Regular"}}>${props.weapon.Cost}</span></p>
                </div>
              </div>

              <div className="flex justify-center space-x-2">
                <p className="w-full text-left text-white text-opacity-95 mb-0" style={{fontFamily: 'Astronomus-Italic'}}>DPS: {props.weapon.baseDamagePerSecond}</p>
                  <ValueBarComponent baseValue={props.weapon.baseDamagePerSecond} barLengthMultiplier={2} color={"green"} hideValue={true}/>
              </div>

              <div className="flex justify-center space-x-2">
                <p className="w-full text-left text-white text-opacity-95 mb-0" style={{fontFamily: 'Astronomus-Italic'}}>Range: {props.weapon.baseRange}</p>
                <ValueBarComponent baseValue={props.weapon.baseRange} barLengthMultiplier={0.7} color={"cyan"} hideValue={true}/>
              </div>


              {/*<p className="text-left text-white text-opacity-95 mb-0 text-sm" style={{fontFamily: 'Astronomus-Italic'}}>Range: {props.weapon.baseRange}</p>*/}
              {/*<ValueBarComponent value={props.weapon.baseRange} barLengthMultiplier={0.7} color={"cyan"} hideValue={true}/>*/}
            </>
          ) : <></>}
        </div>
        <AssemblySideButton height={8} onClick={props.onNavigateRight} direction={1}/>
      </div>
    </AssemblyContainer>
  </>;
}

export default AssemblyUnitPropertyComponent;
