import React, { ReactElement } from 'react';
import { AssemblyProvider } from './parts/assembly.provider';
import { AssemblyComponent } from './parts/assembly.component';

export function AssemblyStandalone(): ReactElement {
  return (
    <AssemblyProvider>
      <AssemblyComponent />
    </AssemblyProvider>
  );
}
