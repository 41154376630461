import {createContext, PropsWithChildren, useEffect, useMemo} from 'react';
import { AssemblyStore } from './assembly.store';

export const ReplaysContext = createContext<AssemblyStore>(
  null as unknown as AssemblyStore
);

export function AssemblyProvider({
  children,
}: PropsWithChildren<Record<never, never>>): JSX.Element {
  const store = useMemo(() => new AssemblyStore(), []);

  useEffect(() => {
    return () => {
      store.reset();
    };
  }, []);

  return <ReplaysContext.Provider value={store}>{children}</ReplaysContext.Provider>;
}
