import { BehaviorSubject, filter, Observable } from 'rxjs';
import { i18nObject } from './i18n/i18n-util';
import { Locales, TranslationFunctions } from './i18n/i18n-types';
import { loadAllLocales } from './i18n/i18n-util.sync';
import { array$$ } from './helpers/observable.helper';

const l = new BehaviorSubject<[TranslationFunctions] | []>([]);
export const l$ = l.pipe(filter((val) => !!val.length)) as Observable<[TranslationFunctions]>;
export const l$$ = array$$(l$);

export const switchLocale = (locale: Locales) => {
  loadAllLocales();
  l.next([i18nObject(locale)]);
};
