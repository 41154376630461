import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {distinctUntilChanged, map} from 'rxjs';
import {Button, FrameLines} from '@arwes/core';
import {useNavigate} from 'react-router-dom';
import {OptionComponent} from '../../../components/option.component';
import {MainMenuContext} from './main-menu.provider';
import {useObservable} from '../../../helpers/useObservable';
import {l$$} from '../../../locale';
import {interop} from '../../../interop';
import {OptionInterface} from '../../../types/interfaces';
import {globalStore} from '../../../global-store';
import {SpeakerphoneIcon} from "@heroicons/react/solid";
import {VolumeMute, VolumeUp} from "@material-ui/icons";

interface IOptionWithClick extends OptionInterface {
  clickFn: () => void;
}

export function MainMenuComponent(): ReactElement {
  useEffect(() => {
    interop.fetchLobbyList();
    const interval = setInterval(() => {
      interop.fetchLobbyList();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const navigate = useNavigate();
  const l = useObservable(l$$);
  const state = useContext(MainMenuContext);

  const options = useObservable(state.options$$);
  const lobby = useObservable(interop.lobby$$);
  const lobbies = useObservable(interop.lobbies$$);
  const [optionsWithFns, setOptionsWithFns] = useState([] as IOptionWithClick[]);
  const loading = useObservable(globalStore.loading$$);

  function handleSinglePlayerClick(): void {
    interop.singleplayerFn();
  }

  function handleAssemblyClick(): void {
    interop.assemblyScreen(true);
  }

  function handleSettingsClick(): void {
    navigate('settings');
  }

  function handleReplaysClick(): void {
    navigate('replays');
  }

  function handleMapEditorClick(): void {
    interop.loadRteSceneFn();
  }

  function handleQuitClick(): void {
    interop.handleQuitClick();
  }

  useEffect(() => {
    const nonce = options.map((option, index) => {
      let clickFn = (): void => {
        window.open("https://store.steampowered.com/app/2126360/Assembly_RTS__Unleash_Your_Forces/");
      };
      return {...option, clickFn};
    });
    setOptionsWithFns(nonce);
  }, [options]);

  const handleQuickMatchClick = (): void => {
    globalStore.setIsLoading(interop.lobby$);
    interop.quickMatch();
    window.open("https://store.steampowered.com/app/2126360/Assembly_RTS__Unleash_Your_Forces/");
  };

  const handleNewsletterClick = (): void => {
    window.open("https://sean-nowotny.com/newsletter");
  }

  const handleDiscordClick = (): void => {
    window.open("https://discord.gg/7q3h9Wj4n4");
  }

  return l[0] ? (
    <div className="flex h-screen ">
      <p style={{
        position: "absolute",
        marginLeft: "auto",
        marginRight: "auto",
        width: "400px",
        left: "200px",
        right: "0",
        top: "2px",
        color: "gray"
      }}>Website under construction</p>
      {/*<h3 style={{position: "absolute", left: "75vw", top: "88vh"}}>Music on this website by<br/><a href="https://www.captainhookmedia.com">Captain Hook Media</a></h3>*/}
      <p className="text-left text-opacity-95 mb-0" style={{
        color: "#dddd",
        fontSize: "1.5rem",
        fontFamily: 'Astronomus-Italic',
        position: "absolute",
        right: "1rem",
        bottom: "1rem"
      }}>Music on this website by<br/><a style={{background: "black", padding: "0.2rem 2.3rem"}}
                                         href="https://www.captainhookmedia.com">Captain Hook Media</a></p>
      <div className="my-auto ml-40 mr-20">
        <div className="grid grid-cols-12">
          <div className="col-span-3">
            <div style={{marginTop: "-20px"}} className="flex flex-col items-center text-white">
              <p style={{fontSize: "27px", fontFamily: "Sputnik Regular", lineHeight: "0px"}}>ASSEMBLY</p>
              <p style={{fontSize: "100px", fontFamily: "Sputnik Regular", lineHeight: "50px"}}>RTS</p>
            </div>
          </div>
          <div className="col-span-9 text-right">{/* <h1>{l[0].OpenGames()}</h1> */}</div>
        </div>
        <div className="grid grid-cols-12">
          <div className="col-span-2 my-auto">
            {optionsWithFns ? (
              optionsWithFns.map((option, index) => (
                <div key={option.key} className={index ? 'mt-10' : ''} onClick={option.clickFn}>
                  <OptionComponent
                    option={option}
                    palette="secondary"
                    setHoveredOptionKey={state.setHoveredKey}
                    hideOptions$={state.hoveredKey$$[0].pipe(
                      map((key) => key !== option.key),
                      distinctUntilChanged()
                    )}
                  />
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
          {lobbies ? (
            <div className="absolute right-8 top-8">
              {!lobby ? (
                <div className="col-span-4 flex justify-center">
                  <Button
                    disabled={loading}
                    className={loading ? 'animate-pulse' : ''}
                    FrameComponent={FrameLines}
                    onClick={handleQuickMatchClick}>
                    <h3 className="m-0 text-5xl" style={{fontFamily: 'Avalon'}}>
                      {lobbies.length ? 'Join' : 'Wishlist'}
                    </h3>
                  </Button>
                </div>
              ) : (
                <></>
              )}
              <div className="col-span-8 flex flex-col items-center">
                <h1 className="mb-0 mt-4">
                  Join the action
                </h1>
                <div className="bg-white p-4 rounded-3xl bg-opacity-80 mt-60" onClick={handleDiscordClick}>
                  <img src="images/discord-logo-blue.png" width="300px" className="justify-center opacity-100"/>
                </div>
                <div className="bg-white p-4 rounded-3xl mt-3 text-white bg-opacity-0 mt-0" onClick={handleNewsletterClick}>
                  <p style={{fontSize: "45px", fontFamily: "Avalon", lineHeight: "10px", paddingTop: "15px"}}>NEWSLETTER</p>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div/>
  );
}
