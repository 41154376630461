import React, { ReactElement } from 'react';
import { EscMenuPanel } from '../../components/esc-menu-panel.component';

export function RteStandalone(): ReactElement {
  return (
    <>
      <EscMenuPanel />
    </>
  );
}
