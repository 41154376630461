import React, { ReactElement } from 'react';
import { PiscesTextComponent } from '../../../components/pisces-text.component';
import { SettingsOptionComponent } from '../../../components/settings-option.component';
import { useObservable } from '../../../helpers/useObservable';
import { interop } from '../../../interop';

export function GraphicsSettingsComponent(): ReactElement {
  const graphicsSettings = useObservable(interop.graphicsSettings$$);

  function setSsgi(val: boolean): void {
    if (graphicsSettings) {
      interop.changeGraphicsSettings({ ...graphicsSettings, SSGI: val });
    }
  }

  function setAo(val: boolean): void {
    if (graphicsSettings) {
      interop.changeGraphicsSettings({ ...graphicsSettings, AmbientOcclusion: val });
    }
  }

  function setDynamicLights(val: boolean): void {
    if (graphicsSettings) {
      interop.changeGraphicsSettings({ ...graphicsSettings, DynamicLights: val });
    }
  }

  function setAntiAliasing(val: boolean): void {
    if (graphicsSettings) {
      interop.changeGraphicsSettings({ ...graphicsSettings, AntiAliasing: val });
    }
  }

  function setFilmGrain(val: boolean): void {
    if (graphicsSettings) {
      interop.changeGraphicsSettings({ ...graphicsSettings, FilmGrain: val });
    }
  }

  function setChromaticAberration(val: boolean): void {
    if (graphicsSettings) {
      interop.changeGraphicsSettings({ ...graphicsSettings, ChromaticAberration: val });
    }
  }

  function setHighFidelity(val: boolean): void {
    if (graphicsSettings) {
      interop.changeGraphicsSettings({ ...graphicsSettings, HighFidelity: val });
    }
  }

  function setSnowVFX(val: boolean): void {
    if (graphicsSettings) {
      interop.changeGraphicsSettings({ ...graphicsSettings, SnowVFX: val });
    }
  }

  function setDistortionVFX(val: boolean): void {
    if (graphicsSettings) {
      interop.changeGraphicsSettings({ ...graphicsSettings, DistortionVFX: val });
    }
  }

  function setHighQualityTerrain(val: boolean): void {
    if (graphicsSettings) {
      interop.changeGraphicsSettings({ ...graphicsSettings, HighQualityTerrain: val });
    }
  }

  return graphicsSettings ? (
    <>
      <div className="col-span-12 grid grid-cols-12 mx-10 mt-5 bg-gray-800 mx-12 bg-opacity-30">
        <div className="col-span-12 grid grid-cols-12 bg-gray-700 bg-opacity-0 m-3 gap-3">
          <div className="col-span-12 ml-2">
            <PiscesTextComponent>
              <span style={{ fontFamily: 'Sputnik Regular', letterSpacing: '2px' }}>LIGHTING</span>
            </PiscesTextComponent>
          </div>
          {/*<SettingsOptionComponent setState={setSsgi} state={graphicsSettings.SSGI}>*/}
          {/*  Advanced Screen-Space Global Illumination*/}
          {/*</SettingsOptionComponent>*/}
          <SettingsOptionComponent setState={setAo} state={graphicsSettings.AmbientOcclusion}>
            Ambient Occlusion
          </SettingsOptionComponent>
          {/*<SettingsOptionComponent*/}
          {/*  setState={setDynamicLights}*/}
          {/*  state={graphicsSettings.DynamicLights}>*/}
          {/*  Dynamic Lights*/}
          {/*</SettingsOptionComponent>*/}
          <SettingsOptionComponent setState={setSnowVFX} state={graphicsSettings.SnowVFX}>
            Snow VFX
          </SettingsOptionComponent>
          <SettingsOptionComponent setState={setDistortionVFX} state={graphicsSettings.DistortionVFX}>
            Distortion VFX
          </SettingsOptionComponent>
          <SettingsOptionComponent setState={setHighQualityTerrain} state={graphicsSettings.HighQualityTerrain}>
            High Quality Terrain
          </SettingsOptionComponent>
          <SettingsOptionComponent setState={setHighFidelity} state={graphicsSettings.HighFidelity}>
            High-Fidelity Rendering
          </SettingsOptionComponent>
        </div>
      </div>
      <div className="col-span-12 grid grid-cols-12 mx-10 mt-5 bg-gray-800 mx-12 bg-opacity-30">
        <div className="col-span-12 grid grid-cols-12 bg-gray-700 bg-opacity-0 m-3 gap-3">
          <div className="col-span-12 ml-2">
            <PiscesTextComponent>
              <span
                style={{
                  fontFamily: 'Sputnik Regular',
                  letterSpacing: '2px',
                }}>
                Post-Processing
              </span>
            </PiscesTextComponent>
          </div>
          <SettingsOptionComponent setState={setAntiAliasing} state={graphicsSettings.AntiAliasing}>
            Deferred Anti-Aliasing
          </SettingsOptionComponent>
          <SettingsOptionComponent
            setState={setChromaticAberration}
            state={graphicsSettings.ChromaticAberration}>
            Chromatic Aberration
          </SettingsOptionComponent>
          <SettingsOptionComponent setState={setFilmGrain} state={graphicsSettings.FilmGrain}>
            Film Grain
          </SettingsOptionComponent>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
}
