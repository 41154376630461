import React, {ReactElement, useContext} from 'react';
import {useObservable} from '../../../helpers/useObservable';
import {ReplaysContext} from "./assembly.provider";
import {interop} from "../../../interop";
import AddIcon from '@material-ui/icons/Add';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import {EVehicleKind} from "../../../types/enums/vehicle-kind.enum";
import {EUnitKind} from "../../../types/enums/unit-kind.enum";
import AssemblyContainer from "../../../components/assembly-container.component";
import AssemblySideButton from "../../../components/assembly-side-button.component";
import AssemblyUnitPropertyComponent from "../../../components/assembly-unit-property.component";
import {EWeaponKind} from "../../../types/enums/weapon-kind.enum";
import {ESpecialization} from "../../../types/enums/specialization.enum";
import {EHoverpadKind} from "../../../types/enums/hoverpad-kind.enum";
import ValueBarComponent from "../../../components/value-bar.component";
import DamageIcon from "../../../components/damage-icon.component";
import {EDamageKind} from "../../../types/enums/damage-kind.enum";
import SpecializationIcon from "../../../components/specialization-icon.component";


export function AssemblyComponent(): ReactElement {
  const state = useContext(ReplaysContext);
  const agents = useObservable(state.agents$$);
  const selectedAgent = useObservable(state.selectedAgent$$);
  const selectedAgentIndex = useObservable(state.selectedAgentIndex$$);
  const vehicleKindEntryCount = Object.keys(EVehicleKind).filter(k => isNaN(Number(k))).length;

  return agents ? (
      <div className="mt-20 w-full">
        <div className="my-auto ml-20 mr-20">
          {/*<div className="grid place-items-center">*/}
          {/*  <h1 className="text-8xl" style={{fontFamily: 'Sputnik Outline'}}>*/}
          {/*    Assembly*/}
          {/*  </h1>*/}
          {/*</div>*/}
          <div className="grid grid-cols-12 p-5">
            {/*<div className="col-span-12 ml-2">*/}
            {/*  <PiscesTextComponent>*/}
            {/*    <span style={{ fontFamily: 'Sputnik Regular', letterSpacing: '2px' }}>Hull</span>*/}
            {/*  </PiscesTextComponent>*/}
            {/*</div>*/}

            <div className="col-span-12 grid grid-cols-12 p-5 gap-0 h-96">

              <div className="flex flex-col space-y-0 col-span-2">
                <AssemblyContainer>
                  <div className="col-span-12 grid grid-cols-12 gap-0 border-2 border-opacity-0 border-neutral-600">
                    <div onClick={() => {
                    }}
                         className="col-span-1 hover:bg-opacity-90 flex border-4 border-r-0 border-cyan-300 justify-center content-center h-12 w-full items-center p-0 shadow-md dark:border-neutral-600 dark:shadow-white/20 bg-opacity-95 bg-cyan-900">
                      <ArrowLeft fontSize="large"/>
                    </div>
                    <div
                      className="col-span-10 hover:bg-opacity-90 flex justify-center content-center h-12 w-full items-center p-0 shadow-md border-neutral-900 dark:shadow-white/20 bg-opacity-95 bg-cyan-900">
                      <h2 className="m-0 text-center text-xl text-neutral-100">Vehicles</h2>
                    </div>
                    <div onClick={() => {
                    }}
                         className="col-span-1 hover:bg-opacity-90 flex border-4 border-l-0 border-cyan-300 justify-center content-center h-12 w-full items-center p-0 shadow-md dark:border-neutral-600 dark:shadow-white/20 bg-opacity-95 bg-cyan-900">
                      <ArrowRight fontSize="large"/>
                    </div>
                  </div>
                </AssemblyContainer>

                {agents.map((agent, i) => (
                    <div key={`vehicle${agent.unitKind} ${i}`}>
                      <AssemblyContainer>
                        <div
                          className="col-span-12 grid grid-cols-12 gap-0 border-2 border-opacity-0 border-neutral-600"
                          style={{
                            borderWidth: agent.IsSelected ? "2px 2px" : "0px",
                            border: agent.IsSelected ? "dashed" : ""
                          }}>
                          <AssemblySideButton height={10} onClick={() => {
                            interop.previousVehicleHullForIndex(i)
                          }} direction={-1}/>
                          <div onClick={() => state.setSelectedAgentIndex(i)}
                            // className={(agent.IsSelected ? "border-cyan-500" : "border-neutral-600") + "col-span-12 bg-opacity-95 bg-black flex justify-center content-center h-40 w-full items-center border p-0 shadow-md dark:shadow-white/20"}>
                            // style={{borderWidth: agent.IsSelected ? "2px 2px" : "0px", border: agent.IsSelected ? "dashed": ""}}
                               className="col-span-10 flex flex-col justify-center content-center h-40 w-full p-2 shadow-md border-neutral-900 dark:shadow-white/20 bg-opacity-95 bg-cyan-900">
                            <div className="relative flex justify-center items-center w-full">
                              <h2 className="text-center text-white opacity-95 text-xl mb-0 z-10">
                                {EUnitKind[agent.unitKind]}
                              </h2>
                              {agent.specialization != ESpecialization.None ?
                                <div className="absolute left-0">
                                  <SpecializationIcon specialization={agent.specialization} paddingTop={2}/>
                                </div>
                                : <></>
                              }
                              <p className="absolute right-0 text-blue-300"
                                 style={{
                                   fontFamily: 'Astronomus-Regular',
                                   marginBottom: "-0.3rem",
                                   top: "50%",
                                   transform: "translateY(-50%)",
                                   borderBottom: "2px double"
                                 }}>
                                ${agent.baseCost + agent.primaryWeapon.Cost + agent.secondaryWeapon.Cost}
                              </p>
                            </div>

                            <p className="text-left text-white text-opacity-95 mb-0 text-sm"
                               style={{fontFamily: 'Astronomus-Italic'}}>Health</p>
                            <ValueBarComponent baseValue={agent.HealthPoints} barLengthMultiplier={0.5} color={"cyan"}/>

                            <p className="text-left text-white text-opacity-95 mb-0 text-sm"
                               style={{fontFamily: 'Astronomus-Italic'}}>Speed</p>

                            <ValueBarComponent baseValue={agent.baseSpeed}
                                               totalValue={
                                                 agent.baseSpeed * (agent.hoverpad.SpeedFactor == 0 ? 1 : agent.hoverpad.SpeedFactor) *
                                                 (agent.primaryWeapon?.SpeedFactor ? agent.primaryWeapon.SpeedFactor : 1) *
                                                 (agent.secondaryWeapon?.SpeedFactor ? agent.secondaryWeapon.SpeedFactor : 1)
                                               }
                                               barLengthMultiplier={0.5} color={"cyan"}/>


                            <div className="flex space-x-1 p-0 m-0">
                              <p className="text-left text-white text-opacity-95 mb-0 text-sm"
                                 style={{fontFamily: 'Astronomus-Italic', paddingTop: "0.25rem"}}>Armor:</p>
                              <div className="w-1"></div>
                              <DamageIcon damageKind={EDamageKind.Grenade} damageFactor={agent.GrenadeDmgFactor}
                                          paddingTop={2}/>
                              <DamageIcon damageKind={EDamageKind.Rocket} damageFactor={agent.RocketDmgFactor}
                                          paddingTop={2}/>
                              <DamageIcon damageKind={EDamageKind.Cannon} damageFactor={agent.CannonDmgFactor}
                                          paddingTop={2}/>
                              <DamageIcon damageKind={EDamageKind.Bullet} damageFactor={agent.BulletDmgFactor}
                                          paddingTop={2}/>
                              <DamageIcon damageKind={EDamageKind.Sniper} damageFactor={agent.SniperDmgFactor}
                                          paddingTop={2}/>
                            </div>

                            <div className="flex space-x-3 p-0" style={{marginTop: "-1rem"}}>
                              <p className="text-left text-white text-opacity-95 mb-0 text-sm"
                                 style={{fontFamily: 'Astronomus-Italic', paddingTop: "0.25rem"}}>Weaponry:</p>
                              <div className="w-1"></div>
                              {
                                agent.primaryWeaponKind != EWeaponKind.None ?
                                  <div className="flex"><DamageIcon damageKind={agent.primaryWeapon.DamageKind}
                                                                    paddingTop={2}/>
                                    <p className="text-left text-white text-opacity-95 mb-0 text-sm ml-1"
                                       style={{
                                         fontFamily: 'Astronomus-Italic',
                                         paddingTop: "0.25rem"
                                       }}>{Math.round(agent.primaryWeapon.baseDamagePerSecond)}</p>
                                  </div>
                                  : <></>
                              }
                              {
                                agent.secondaryWeaponKind != EWeaponKind.None ?
                                  <div className="flex"><DamageIcon damageKind={agent.secondaryWeapon.DamageKind}
                                                                    paddingTop={2}/>
                                    <p className="text-left text-white text-opacity-95 mb-0 text-sm ml-1"
                                       style={{
                                         fontFamily: 'Astronomus-Italic',
                                         paddingTop: "0.25rem"
                                       }}>{Math.round(agent.secondaryWeapon.baseDamagePerSecond)}</p>
                                  </div>
                                  : <></>
                              }
                            </div>
                            {/*<div style={{marginTop: "-0.5rem"}} className="flex space-x-10">*/}
                            {/*{*/}
                            {/*  agent.primaryWeaponKind != EWeaponKind.None ?*/}
                            {/*    <ValueBarComponent value={agent.primaryWeapon?.baseDamagePerSecond} barLengthMultiplier={2} color={"green"}/>*/}
                            {/*    :<></>*/}
                            {/*}*/}
                            {/*{*/}
                            {/*  agent.secondaryWeaponKind != EWeaponKind.None ?*/}
                            {/*    <ValueBarComponent value={agent.secondaryWeapon?.baseDamagePerSecond} barLengthMultiplier={2} color={"green"}/>*/}
                            {/*    :<></>*/}
                            {/*}*/}
                            {/*</div>*/}

                          </div>
                          <AssemblySideButton height={10} onClick={() => {
                            interop.nextVehicleHullForIndex(i)
                          }} direction={1}/>
                        </div>
                      </AssemblyContainer>
                    </div>
                  )
                )}

                {
                  agents.length < vehicleKindEntryCount ?
                    (<AssemblyContainer>
                        <div onClick={() => interop.addVehicleToAssembly(EVehicleKind.Mainz)}
                             className="col-span-12 hover:bg-opacity-90 flex justify-center content-center h-40 w-full items-center p-0 shadow-md dark:border-neutral-600 dark:shadow-white/20 bg-opacity-95 bg-cyan-900">
                          <AddIcon fontSize="large"/>
                        </div>
                      </AssemblyContainer>
                    ) : (<></>)
                }
              </div>

              <div className="flex flex-col space-y-0 col-span-8 item">
              </div>

              {selectedAgent ?
                (
                  <div className="flex flex-col space-y-0 col-span-2 item">
                    <div style={{marginBottom: "0.5rem"}}>
                      <AssemblyUnitPropertyComponent
                        title={"specialization"}
                        specialization={selectedAgent.specialization}
                        description={selectedAgent.specializationDescription}
                        currentItemTitle={ESpecialization[selectedAgent.specialization]}
                        onNavigateLeft={() => {interop.previousSpecializationForIndex(selectedAgentIndex)}}
                        onNavigateRight={() => {interop.nextSpecializationForIndex(selectedAgentIndex)}}
                      />
                    </div>
                    <div style={{marginBottom: "0.5rem"}}>
                      {/*<p style={{fontFamily: "Nostromo"}}>{Object.keys(selectedAgent).sort().join("\n")}</p>*/}
                      {/*{typeof selectedAgent.specializationDescription}*/}
                      {/*{selectedAgent.specializationDescription.length}*/}
                      <AssemblyUnitPropertyComponent
                        title={"hoverpads"}
                        hoverpad={selectedAgent.hoverpad}
                        currentItemTitle={EHoverpadKind[selectedAgent.hoverpadKind]}
                        onNavigateLeft={() => {
                          interop.previousHoverpadsForIndex(selectedAgentIndex)
                        }}
                        onNavigateRight={() => {
                          interop.nextHoverpadsForIndex(selectedAgentIndex)
                        }}
                      />
                    </div>
                    <div style={{marginBottom: "0rem"}}>
                      <AssemblyUnitPropertyComponent
                        title={"primary armament"}
                        weapon={selectedAgent.primaryWeapon}
                        currentItemTitle={EWeaponKind[selectedAgent.primaryWeaponKind]}
                        onNavigateLeft={() => {
                          interop.previousPrimaryWeaponForIndex(selectedAgentIndex)
                        }}
                        onNavigateRight={() => {
                          interop.nextPrimaryWeaponForIndex(selectedAgentIndex)
                        }}
                      />
                    </div>
                    {selectedAgent.primaryWeaponKind != EWeaponKind.None ?
                      (
                        <div style={{marginBottom: "0.5rem"}}>
                          <AssemblyUnitPropertyComponent
                            title={"secondary"}
                            weapon={selectedAgent.secondaryWeapon}
                            currentItemTitle={EWeaponKind[selectedAgent.secondaryWeaponKind]}
                            onNavigateLeft={() => {
                              interop.previousSecondaryWeaponForIndex(selectedAgentIndex)
                            }}
                            onNavigateRight={() => {
                              interop.nextSecondaryWeaponForIndex(selectedAgentIndex)
                            }}
                          />
                        </div>
                      ) : <></>
                    }
                  </div>
                )
                : <></>
              }
            </div>
          </div>
        </div>
      </div>
    ) :
    (
      <></>
    );
}
