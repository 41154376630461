import { distinctUntilChanged, filter, interval, map } from 'rxjs';
import { unityFilePathRoot } from './env';
import { switchLocale } from './locale';

let environment: 'unity' | 'localhost' = 'localhost';

export const environment$ = interval(100).pipe(
  filter(() => !!environment),
  map(() => environment),
  distinctUntilChanged((a, b) => a === b)
);

(() => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', unityFilePathRoot, true);
  xhr.timeout = 1000;
  xhr.onload = () => {
    environment = 'unity';
  };
  xhr.ontimeout = () => {
    environment = 'localhost';
    switchLocale('en');
  };
  xhr.send();
})();
