import {createContext, PropsWithChildren, useEffect, useMemo} from 'react';
import { MainMenuStore } from './main-menu.store';

export const MainMenuContext = createContext<MainMenuStore>(null as unknown as MainMenuStore);

export function MainMenuProvider({
  children,
}: PropsWithChildren<Record<never, never>>): JSX.Element {
  const store = useMemo(() => new MainMenuStore(), []);

  useEffect(() => {
    return () => {
      store.reset();
    };
  }, []);

  return <MainMenuContext.Provider value={store}>{children}</MainMenuContext.Provider>;
}
