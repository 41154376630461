import React, { ReactElement, useEffect } from 'react';
import { Checkbox, Slider } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { ControlTypeEnum } from '../types/enums/control-type.enum';

export function SettingsOptionComponent<T>({
  controlType,
  min,
  max,
  children,
  setState,
  state,
}: {
  controlType?: ControlTypeEnum;
  min?: number;
  max?: number;
  children: any;
  setState: (value: T) => void;
  state: T;
}): ReactElement {
  function handleClick(): void {
    setState(!state as any);
  }

  function handleChange(event: any, val: number): void {
    setState(val as any);
  }

  const slider = controlType === ControlTypeEnum.Slider;

  return (
    <div className="grid grid-cols-12 col-span-4 p-3 bg-cyan-400 bg-opacity-5">
      <div className={`col-span-${slider ? 12 : 9} flex`}>
        <h3 className="inline-block align-middle mb-0" style={{ fontFamily: 'Astronomus-Italic' }}>
          {children}
        </h3>
      </div>
      <div className={`col-span-${slider ? 12 : 3} grid justify-items-end`}>
        {/* <Checkbox onChange={() => {}} inputProps={{'aria-label': 'secondary checkbox'}}/> */}
        {/* <SpeedOutlined /> */}
        {slider ? (
          <Slider
            value={state as any}
            onChange={handleChange as any}
            aria-labelledby="continuous-slider"
            min={min || 0}
            max={max || 100}
          />
        ) : (
          // <Slider
          //   onChange={handleChange as any}
          //   style={{color: '#f3f3f3'}}
          //   defaultValue={state as any}
          //   aria-labelledby="continuous-slider"
          //   valueLabelDisplay="off"
          // />
          <Checkbox
            // inputProps={{'aria-label': 'primary checkbox'}}
            onChange={handleClick}
            defaultChecked={!!state}
            icon={<CheckBoxOutlineBlankIcon style={{ color: '#01d9d9' }} fontSize="large" />}
            checkedIcon={<CheckBoxIcon fontSize="large" />}
            style={{ color: '#f3f3f3' }}
            color="primary"
          />
        )}
      </div>
    </div>
  );
}
