import {createContext, PropsWithChildren, useEffect, useMemo} from 'react';
import { MatchmakingStore } from './matchmaking.store';

export const MatchmakingContext = createContext<MatchmakingStore>(
  null as unknown as MatchmakingStore
);

export function MatchmakingProvider({
  children,
}: PropsWithChildren<Record<never, never>>): JSX.Element {
  const store = useMemo(() => new MatchmakingStore(), []);

  useEffect(() => {
    return () => {
      store.reset();
    };
  }, []);

  return <MatchmakingContext.Provider value={store}>{children}</MatchmakingContext.Provider>;
}
