import { map, Observable, Subject } from 'rxjs';
import { OptionInterface } from '../../../types/interfaces';
import { l$ } from '../../../locale';
import { array$$, string$$ } from '../../../helpers/observable.helper';
import { IStore } from '../../../types/interfaces/store.interface';

const addKey = (option: { title: string; options?: any[] }): OptionInterface => ({
  ...option,
  options: option?.options?.map(addKey),
  key: option.title + option?.options?.length,
});

export class MainMenuStore implements IStore {
  private readonly hoveredKeySubject = new Subject<string>();

  private readonly options$: Observable<OptionInterface[]> = l$.pipe(
    map((l) =>
      [
        { title: 'introduction' },
        { title: 'setting' },
        { title: 'features' },
        { title: 'challenges' },
        { title: 'story' },
        { title: 'the assembly' },
        { title: 'map editor' },
        { title: 'contact' },
      ]
        .map(addKey)
    )
  );

  readonly hoveredKey$$ = string$$(this.hoveredKeySubject);
  readonly options$$ = array$$(this.options$);
  private readonly destructionSubject = new Subject<void>();

  readonly setHoveredKey = (val: string): void => this.hoveredKeySubject.next(val);
  readonly reset = (): void => {
    this.destructionSubject.next();
    this.destructionSubject.complete();
  };
}
