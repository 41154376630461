import React, { ReactElement } from 'react';
import { MatchmakingProvider } from './parts/matchmaking.provider';
import { MatchmakingComponent } from './parts/matchmaking.component';

export function MatchmakingStandalone(): ReactElement {
  return (
    <MatchmakingProvider>
      <MatchmakingComponent />
    </MatchmakingProvider>
  );
}
