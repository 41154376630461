import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, FrameLines, Text } from '@arwes/core';
import { useObservable } from '../../../helpers/useObservable';
import { l$$ } from '../../../locale';
import { interop } from '../../../interop';
import Dropdown from '../../../components/dropdown.component';
import { MatchmakingContext } from './matchmaking.provider';
import GrayTextComponent from '../../../components/gray-text.component';

export function MatchmakingComponent(): ReactElement {
  const navigate = useNavigate();
  const l = useObservable(l$$);
  const state = useContext(MatchmakingContext);

  const lobby = useObservable(interop.lobby$$);
  const [ready, setReady] = useState(false);

  const playerMapChoices = useObservable(state.playerMapChoices$$);
  const myWorkshopSubscriptions = useObservable(interop.workshopSubscriptions$$);
  const selectedOption = useObservable(state.selectedMap$$);

  // useEffect(() => {
  //   console.log('LOBBY', lobby)
  // }, [lobby])

  useEffect(() => {
    interop.fetchWorkshopSubscriptions();

    const interval = setInterval(() => {
      interop.fetchWorkshopSubscriptions();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
  }, [myWorkshopSubscriptions]);

  function handleReadyClick(): void {
    interop.readyUp(!ready);
    setReady(!ready);
  }

  function handleLeaveClick(): void {
    navigate('/');
    interop.leaveMatch();
  }

  function handleMapSelection(publishedFileId: string): void {
    state.setSelectedMapId(publishedFileId);
    interop.setSelectedWorkshopMap(publishedFileId);
  }

  return l[0] && lobby ? (
    <div className="mt-20 w-full">
      <div className="my-auto ml-20 mr-20">
        <div className="grid place-items-center">
          <h1 className="text-8xl" style={{ fontFamily: 'Sputnik Outline' }}>
            Looking for players...
          </h1>
        </div>
        <div className="mx-72 mb-12 opacity-80">{/* <LinearProgress /> */}</div>
        <div className="grid grid-cols-12 bg-gray-900 bg-opacity-30 p-5 ">
          <div className="col-span-4 grid grid-cols-12 bg-gray-900 bg-opacity-30 content-start space-x-2">
            {playerMapChoices.map((mapChoice) => (
              <div
                key={mapChoice.PublishedFileId + mapChoice.Votee}
                className="col-span-4 grid place-items-center">
                <div
                  onClick={() => handleMapSelection(mapChoice.PublishedFileId)}
                  className="w-full h-full mt-7"
                  style={{
                    width: '118px',
                    height: '118px',
                    border: '5px solid ' + (mapChoice.topVoted ? 'green' : 'white'),
                    position: 'relative',
                  }}>
                  <img
                    src={mapChoice.ImageData ? 'data:image/png;base64,' + mapChoice.ImageData : ''}
                    alt="Loading..."
                    className="w-full h-full object-cover object-center "
                  />
                  {mapChoice.isSelected && (
                    <div
                      className="bg-cyan-300 bg-opacity-70"
                      style={{
                        position: 'absolute',
                        top: '0',
                        right: '0',
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%',
                      }}
                    />
                  )}
                  <div style={{ position: 'absolute', bottom: '0', right: '0', padding: '5px' }}>
                    {mapChoice.votes}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="col-span-4 grid grid-cols-11 bg-gray-900 bg-opacity-30 p-5 pb-60">
            <div className="col-span-5 grid place-items-end ">
              <div className="text-6xl" style={{ fontFamily: 'Avalon' }}>
                Ready
              </div>
              <div className="text-6xl">{lobby?.Members.filter((el) => el.IsReady).length}</div>
            </div>
            <div className="col-span-1 grid place-items-center bg-cyan-900 mx-5" />
            <div className="col-span-5 grid place-items-start">
              <div className="text-6xl" style={{ fontFamily: 'Avalon' }}>
                Found
              </div>
              <div className="text-6xl">{lobby?.Members.length}</div>
            </div>
            <div className="grid place-items-center col-span-11 mt-12">
              <div className="text-8xl" style={{ fontFamily: 'Sputnik Regular' }}>
                FFA
              </div>
            </div>
            <div
              className={
                'grid place-items-center col-span-11 mt-8 rounded bg-gradient-to-r from-black via-cyan-500 to-black ' +
                (playerMapChoices.length ? 'bg-opacity-75' : 'bg-opacity-0')
              }>
              <Button
                disabled={playerMapChoices.length === 0}
                onClick={handleReadyClick}
                FrameComponent={FrameLines}>
                <h3
                  className={
                    'mt-0 mb-1 text-8xl ' +
                    (playerMapChoices.length ? 'opacity-90' : 'opacity-0') +
                    ' ' +
                    (ready ? 'animate-pulse' : '')
                  }>
                  READY
                </h3>
              </Button>
            </div>
            <div className="grid place-items-center col-span-11 mt-12 bg-opacity-75 rounded">
              <Button onClick={handleLeaveClick}>
                <h3 className="mt-0 mb-1 text-xl opacity-90">LEAVE LOBBY</h3>
              </Button>
            </div>
          </div>
          <div className="col-span-4 grid grid-cols-12 bg-gray-900 bg-opacity-30 p-5 content-start">
            <div className="col-span-12">
              <Dropdown<(typeof myWorkshopSubscriptions)[0], 'PublishedFileId', 'Title'>
                selectedOption={selectedOption}
                options={myWorkshopSubscriptions}
                idKey="PublishedFileId"
                titleKey="Title"
                onSelect={handleMapSelection}
                Container={GrayTextComponent}
                itemName="Workshop Map"
                cta="Please subscribe to a public Steam Workshop map"
              />
            </div>
            <div className="col-span-12">
              {selectedOption ? (
                <Card
                  image={{
                    src: selectedOption?.ImageData
                      ? 'data:image/png;base64,' + selectedOption.ImageData
                      : '',
                    alt: 'Loading...',
                  }}
                  title={selectedOption?.Title}
                  options={
                    <Button palette="secondary">
                      <Text>Details</Text>
                    </Button>
                  }
                  style={{ maxWidth: 1000 }}>
                  <Text>{selectedOption?.Description}</Text>
                </Card>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
}
