import { BehaviorSubject } from 'rxjs';
import { IStore } from '../../../types/interfaces/store.interface';
import { bool$$, number$$ } from '../../../helpers/observable.helper';
import { interop } from '../../../interop';

export class SettingsStore implements IStore {
  ssgi = new BehaviorSubject<boolean>(true);
  ambientOcclusion = new BehaviorSubject<boolean>(true);
  dynamicLights = new BehaviorSubject<boolean>(true);
  antiAliasing = new BehaviorSubject<boolean>(true);
  chromaticAberration = new BehaviorSubject<boolean>(true);
  filmGrain = new BehaviorSubject<boolean>(true);

  ssgi$$ = bool$$(this.ssgi);
  ambientOcclusion$$ = bool$$(this.ambientOcclusion);
  dynamicLights$$ = bool$$(this.dynamicLights);
  antiAliasing$$ = bool$$(this.antiAliasing);
  chromaticAberration$$ = bool$$(this.chromaticAberration);
  filmGrain$$ = bool$$(this.filmGrain);

  constructor() {
    // console.log('constructing settings store');
    // this.mainMenuMusicVolume.subscribe(val => interop.setMainMenuMusicVolume(val))
    // this.mainMenuAmbienceVolume.subscribe(val => interop.setMainMenuAmbienceVolume(val))
    // this.inGameMusicVolume.subscribe(val => interop.setInGameMusicVolume(val))
  }

  readonly reset = (): void => {
    this.ssgi.complete();
    this.ambientOcclusion.complete();
    this.dynamicLights.complete();
    this.antiAliasing.complete();
    this.chromaticAberration.complete();
    this.filmGrain.complete();
  };
}
