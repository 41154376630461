import { unityFilePathRoot } from '../env';
import { environment$ } from '../environment';
import { map } from 'rxjs';

export const fileHelper = {
  filePath$(str: string) {
    return environment$.pipe(
      map((env) => (env === 'unity' ? unityFilePathRoot : '') + str)
    );
  },

  soundFilePath$(str: string, ext = 'ogg') {
    return environment$.pipe(
      map(
        (env) =>
          (env === 'unity' ? unityFilePathRoot : '') +
          '/sounds/' +
          str +
          '.' +
          ext
      )
    );
  },

  imageFilePath$(str: string, ext = 'png') {
    return environment$.pipe(
      map(
        (env) =>
          (env === 'unity' ? unityFilePathRoot : '') +
          '/images/' +
          str +
          '.' +
          ext
      )
    );
  },
};
