import React, { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useObservable } from '../../../helpers/useObservable';
import { l$$ } from '../../../locale';
import { OptionComponent } from '../../../components/option.component';
import { GraphicsSettingsComponent } from './graphics-settings.component';
import { AudioSettingsComponent } from './audio-settings.component';

enum SettingOption {
  Graphics = 'Graphics',
  Audio = 'Audio',
  Controls = 'Controls',
  Preferences = 'Preferences',
}

export function SettingsComponent(): ReactElement {
  const navigate = useNavigate();
  const l = useObservable(l$$);
  const options = [
    SettingOption.Graphics,
    SettingOption.Audio,
    SettingOption.Controls,
    SettingOption.Preferences,
  ];
  const [highlighted, setHighlighted] = useState(
    options.map((option, i) => ({ option, active: i === 0 }))
  );

  function handleClickOption(index: number): void {
    setHighlighted(highlighted.map((el, i) => ({ ...el, active: i === index })));
  }

  function settingsComponentFromSelection(selection: SettingOption): ReactElement {
    let result = <GraphicsSettingsComponent />;
    switch (selection) {
      case SettingOption.Graphics: {
        result = <GraphicsSettingsComponent />;
        break;
      }
      case SettingOption.Audio: {
        result = <AudioSettingsComponent />;
        break;
      }
      default: {
        console.error('no good');
        break;
      }
    }
    return result;
  }

  function settingsComponent(): ReactElement {
    return settingsComponentFromSelection(
      highlighted.find((el) => el.active)?.option as SettingOption
    );
  }

  return l[0] ? (
    <div>
      {/* <div className="fixed h-full w-full flex items-center justify-center"> */}
      {/*   <div style={{marginTop: "40rem"}} className="grid grid-cols-12 bg-gray-900 bg-opacity-30 p-5"> */}
      {/*     <div className="col-span-4 grid place-items-center" onClick={() => {}}> */}
      {/*       <OptionComponent width={90} option={{ title: 'Undo Changes', key: 'undo changes' }} /> */}
      {/*     </div> */}
      {/*     <div className="col-span-4 grid place-items-center" onClick={() => {}}> */}
      {/*       <OptionComponent width={90} option={{ title: 'Reset to Defaults', key: 'reset to defaults' }} /> */}
      {/*     </div> */}
      {/*     <div className="col-span-4 grid place-items-center" onClick={handleSaveConfirmed}> */}
      {/*       <OptionComponent width={90} option={{ title: 'Save Changes', key: 'save changes' }} /> */}
      {/*     </div> */}
      {/*   </div> */}
      {/* </div> */}
      <div className="mt-20 w-full">
        <div className="my-auto ml-20 mr-20">
          <div className="grid place-items-center mb-12">
            <h1 className="text-8xl" style={{ fontFamily: 'Sputnik Outline' }}>
              Settings
            </h1>
          </div>
          <div className="grid grid-cols-12 bg-gray-900 bg-opacity-30 p-5">
            <div className="col-span-12 grid grid-cols-12 bg-gray-900 bg-opacity-30 p-5">
              {highlighted.map((option, index) => (
                <div
                  className="col-span-3 grid place-items-center"
                  onClick={() => handleClickOption(index)}>
                  <OptionComponent
                    option={{ title: option.option, key: option.option }}
                    highlight={option.active}
                  />
                </div>
              ))}
            </div>
            {settingsComponent()}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
}
