import {EDamageKind} from "../types/enums/damage-kind.enum";
import {ESpecialization} from "../types/enums/specialization.enum";

export const variousHelper = {
  ascDescFromNumber(val: number) {
    return val % 3 === 0 ? 'desc' : val % 2 === 0 ? 'asc' : null;
  },

  iconFromDamageKind(damageKind: EDamageKind): string {
    switch(damageKind) {
      case EDamageKind.Cannon: return "cannon"
      case EDamageKind.Bullet: return "bullet"
      case EDamageKind.Sniper: return "sniper"
      case EDamageKind.Rocket: return "missile"
      case EDamageKind.Grenade: return "explosion"
    }
  },

  iconFromSpecialization(specialization: ESpecialization): string {
    switch(specialization) {
      case ESpecialization.Mechanic: return "mechanic"
      case ESpecialization.Engineer: return "engineer"
      case ESpecialization.Medic: return "cross"
      case ESpecialization.Harvester: return "zenith"
      case ESpecialization.Mirage: return "stealth"
    }
    console.error("iconFromSpecialization is missing a case!");
    return "";
  },

  hueShiftFromSpecialization(specialization: ESpecialization): number {
    switch(specialization) {
      case ESpecialization.Mechanic: return 60; // Yellow
      case ESpecialization.Engineer: return 30; // Orange
      case ESpecialization.Medic: return 120; // Green
      case ESpecialization.Harvester: return 210 // ?
      case ESpecialization.Mirage: return 180 // Purple
    }
    return 0;
  },
};
