import React, {ReactElement, useContext, useEffect} from 'react';
import {useObservable} from '../../../helpers/useObservable';
import {ReplaysContext} from "./replays.provider";
import {interop} from "../../../interop";

export function ReplaysComponent(): ReactElement {
  const state = useContext(ReplaysContext);
  const data = useObservable(state.data$$);

  useEffect(() => {
    interop.fetchReplayList();
  }, []);

  function handleRowDoubleClick(replayName: string): void {
    interop.loadReplaySceneFn(replayName);
  }

  function handleDeleteReplayClick(replayName: string): void {
    interop.deleteReplayFn(replayName);
  }

  return data ? (
      <div className="mt-20 w-full">
        <div className="my-auto ml-20 mr-20">
          <div className="grid place-items-center">
            <h1 className="text-8xl" style={{fontFamily: 'Sputnik Outline'}}>
              Replays
            </h1>
          </div>
          <table className="table-auto">
            <thead>
            <tr>
              {data.headers.map((el) => (
                <th key={'header-' + el.id}>{el.data}</th>
              ))}
            </tr>
            </thead>
            <tbody>
            {data.sortedDataset.map((el) => (
              <tr
                style={{cursor: 'pointer'}}
                key={'body-' + el.id}
                onDoubleClick={() => handleRowDoubleClick(el.id.toString())}
              >
                {el.columns.map((x, i) => (
                  <td
                    key={'column-' + x.id}
                    onClick={
                      () => i == el.columns.length - 1
                        ? handleDeleteReplayClick(el.id.toString())
                        : {}
                    }
                  >
                    {x.data}
                  </td>
                ))}
              </tr>
            ))}
            </tbody>
          </table>
          {/*{blurScreen && <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm"/>}*/}
        </div>
      </div>
    ) :
    (
      <></>
    );
}
