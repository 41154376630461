import {createContext, PropsWithChildren, useEffect, useMemo} from 'react';
import { SettingsStore } from './settings.store';

export const SettingsContext = createContext<SettingsStore>(null as unknown as SettingsStore);

export function SettingsProvider({
  children,
}: PropsWithChildren<Record<never, never>>): JSX.Element {
  const store = useMemo(() => new SettingsStore(), []);

  useEffect(() => {
    return () => {
      store.reset();
    };
  }, []);

  return <SettingsContext.Provider value={store}>{children}</SettingsContext.Provider>;
}
