import type { BaseTranslation } from '../i18n-types';

const en: BaseTranslation = {
  Exit: 'Exit',
  Filter: 'Filter',
  Chat: 'Chat',
  Servers: 'Servers',
  Server: 'Server',
  ServerName: 'Server Name',
  GameMode: 'Game Mode',
  Players: 'Players',
  Ping: 'Ping',
  Tutorial: 'Tutorial',
  Singleplayer: 'Singleplayer',
  Multiplayer: 'Multiplayer',
  Profile: 'Profile',
  Options: 'Options',
  Quit: 'Quit',
  Replays: 'Replays',
  Credits: 'Credits',
  CreateNewMatch: 'Create New Match',
  CreateGame: 'Create Game',
  OpenGames: 'Open Games',
  NewGame: 'New Game',
  LoadMatch: 'Load Match',
  Close: 'Close',
  GoBack: 'Go Back',
  Cancel: 'Cancel',
  Maps: 'Maps',
  SelectedMap: 'Selected Map',
  LetOtherPlayersJoin: 'Let Other Players Join',
  LetMorePlayersJoin: 'Let More Players Join',
  SingleplayerGame: 'Singleplayer Game',
  AllowMorePlayersToJoinYourGame: 'Allow More Players To Join Your Game',
  Match: 'Match',
  NewMatch: 'New Match'
};

export default en;
