import { useBleeps } from '@arwes/sounds';
import { ReactElement, ReactNode } from 'react';

export function ButtonBleepProvider({ children }: { children: ReactNode }): ReactElement {
  const bleeps = useBleeps();
  const handleClick: () => void = () => bleeps.click.play();

  return (
    <div role="button" onClick={handleClick}>
      {children}
    </div>
  );
}
