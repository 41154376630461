import React, { ReactElement } from 'react';
import { FrameBox } from '@arwes/core';
import { Observable } from 'rxjs';
import { useBleeps } from '@arwes/sounds';
import { OptionInterface } from '../types/interfaces';
import { ButtonBleepProvider } from './button-bleep.provider';
import { PiscesTextComponent } from './pisces-text.component';

export function OptionComponent({
  width,
  highlight,
  option,
  setHoveredOptionKey,
  hideOptions$,
  activate = true,
  palette = 'primary',
}: {
  width?: number;
  highlight?: boolean;
  option: OptionInterface;
  setHoveredOptionKey?: (val: string) => void;
  hideOptions$?: Observable<boolean>;
  palette?: 'primary' | 'secondary';
  activate?: boolean;
}): ReactElement {
  const bleeps = useBleeps();

  const handleShowOptions = (): void => {
    setHoveredOptionKey && setHoveredOptionKey(option.key);
    bleeps.hover.play();
  };

  return (
    <div onMouseEnter={handleShowOptions}>
      <ButtonBleepProvider>
        {
          // @ts-ignore
          <FrameBox
            style={{ borderWidth: highlight ? '1px' : '0px' }}
            animator={{ activate }}
            palette={palette}
            linesWidths={!option?.options?.length ? [0, 1, 0, 0] : [0, 0, 0, 0]}
            hover>
            <div style={{width: "350px"}}
              className={
                'h-12 p-4 grid justify-center content-center'
              }>
              <PiscesTextComponent>{option.title.toUpperCase()}</PiscesTextComponent>
            </div>
          </FrameBox>
        }
      </ButtonBleepProvider>
    </div>
  );
}
