import React, { ReactElement, useEffect, useState } from 'react';
import { Button, FrameBox, FrameLines } from '@arwes/core';
import { useObservable } from '../helpers/useObservable';
import { interop } from '../interop';
import { SettingsStandalone } from '../pages/settings/settings.standalone';

export function EscMenuPanel(): ReactElement {
  const showEscMenuPanel = useObservable(interop.isEscMenuActive$$);
  const [showSettings, setShowSettings] = useState(false);

  useEffect(() => {
    if (!showEscMenuPanel) {
      setShowSettings(false);
    }
  }, [showEscMenuPanel]);

  function handleMainMenuClick(): void {
    interop.loadMainMenuFn();
  }

  function handleSettingsClick(): void {
    setShowSettings(!showSettings);
  }

  return !showEscMenuPanel ? (
    <></>
  ) : showSettings ? (
    <div className="absolute w-full h-full bg-gray-800 bg-opacity-90">
      <SettingsStandalone />
    </div>
  ) : (
    <div className="absolute w-full h-full bg-gray-800 bg-opacity-90">
      <div className="flex justify-center items-center h-screen">
        {
          // @ts-ignore
          <FrameBox animator={{ activate: true }} linesWidths={[1, 1, 0, 0]}>
            <h1 className="text-center" style={{ fontFamily: 'Astronomus-Italic' }}>
              Menu
            </h1>
            <div className="h-96 w-80">
              <div className="grid justify-center gap-y-0">
                <div className="col-span-12 grid justify-center content-center">
                  <Button
                    FrameComponent={FrameLines}
                    style={{ width: '300px' }}
                    onClick={() => handleMainMenuClick()}>
                    <h1 className="m-0 text-xl">Exit to Main Menu</h1>
                  </Button>
                </div>
                <div className="col-span-12 grid justify-center content-center mt-3">
                  <Button
                    FrameComponent={FrameLines}
                    style={{ width: '300px' }}
                    onClick={() => handleSettingsClick()}>
                    <h1 className="m-0 text-xl">Settings</h1>
                  </Button>
                </div>
                {/* <div className='py-2 col-span-12 grid justify-center content-center'> */}
                {/*  <Button */}
                {/*    FrameComponent={FrameLines} */}
                {/*    style={{ width: '300px' }} */}
                {/*  > */}
                {/*    <h1 className='m-0 text-xl'> */}
                {/*      Continue */}
                {/*    </h1> */}
                {/*  </Button> */}
                {/* </div> */}
              </div>
            </div>
          </FrameBox>
        }
      </div>
    </div>
  );
}
