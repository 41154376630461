import React, { ReactElement } from 'react';
import { SettingsProvider } from './parts/settings.provider';
import { SettingsComponent } from './parts/settings.component';

export function SettingsStandalone(): ReactElement {
  return (
    <SettingsProvider>
      <SettingsComponent />
    </SettingsProvider>
  );
}
