import {ArrowRight, ArrowLeft} from "@material-ui/icons";
import React from "react";
import {interop} from "../interop";

function AssemblySideButton(props: { onClick: () => void, direction: number, height: number }) {
  return props.direction > 0 ? (
    <div onClick={props.onClick}
         style={{height: `${props.height}rem`}}
         className="col-span-1 hover:bg-opacity-80 flex border-4 border-l-0 border-cyan-300 justify-center content-center w-full items-center p-0 shadow-md dark:border-neutral-600 dark:shadow-white/20 bg-opacity-95 bg-cyan-800">
      <ArrowRight fontSize="large"/>
    </div>
  ) : (
    <div onClick={props.onClick}
         style={{height: `${props.height}rem`}}
         className="col-span-1 hover:bg-opacity-80 flex border-4 border-r-0 border-cyan-300 justify-center content-center w-full items-center p-0 shadow-md dark:border-neutral-600 dark:shadow-white/20 bg-opacity-90 bg-cyan-800">
      <ArrowLeft fontSize="large"/>
    </div>
  )
}

export default AssemblySideButton;
