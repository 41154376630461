import {Observable} from "rxjs";

function defaulter$$<T, B = T>($: Observable<T>, d: B): [Observable<T>, B] {
  return [$, d]
}
export function array$$<T>($: Observable<T[]>): [Observable<T[]>, T[]] {
  return defaulter$$($, []) ;
}
export function string$$($: Observable<string>): [Observable<string>, ''] {
  return defaulter$$($, '');
}
export function number$$($: Observable<number>): [Observable<number>, 0] {
  return defaulter$$($, 0);
}
export function bool$$($: Observable<boolean>): [Observable<boolean>, false] {
  return defaulter$$($, false);
}
export function nullable$$<T>($: Observable<T>): [Observable<T>, null] {
  return defaulter$$<T, null>($, null);
}
