import {createContext, PropsWithChildren, useEffect, useMemo} from 'react';
import { ReplaysStore } from './replays.store';

export const ReplaysContext = createContext<ReplaysStore>(
  null as unknown as ReplaysStore
);

export function ReplaysProvider({
  children,
}: PropsWithChildren<Record<never, never>>): JSX.Element {
  const store = useMemo(() => new ReplaysStore(), []);

  useEffect(() => {
    return () => {
      store.reset();
    };
  }, []);

  return <ReplaysContext.Provider value={store}>{children}</ReplaysContext.Provider>;
}
