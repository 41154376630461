import React from "react";

function ValueBarComponent(props: { baseValue: number, totalValue?: number, barLengthMultiplier: number, color: string, hideValue?: boolean }) {
  const excessValue = (props.totalValue || props.baseValue) - props.baseValue;
  const reduction = excessValue < 0 ? Math.abs(excessValue) : 0;
  const addition = excessValue > 0 ? excessValue : 0;
  const leftValue = props.baseValue - reduction;

  return (
    <div className="flex items-center space-x-2 w-full" style={{marginTop: "0rem", marginBottom: "-0rem"}}>
      {/**/}
      <div className="bg-cyan-900 bg-opacity-100 h-2 mb-0 w-full flex items-center space-x2 rounded" style={{paddingTop: "0.3rem", paddingBottom: "0.3rem"}}>
        <div style={{width: `${leftValue * props.barLengthMultiplier}%`}}
             className={`bg-${props.color}-400 h-2 mb-0`}></div>
        <div style={{width: `${reduction * props.barLengthMultiplier}%`}}
             className={`bg-black h-2 mb-0`}></div>
        <div style={{width: `${addition * props.barLengthMultiplier}%`}}
             className={`bg-white h-2 mb-0`}></div>
        <div className="flex-grow"></div>
        {props.hideValue == undefined  && props.hideValue != true ?
          <p className={`text-right text-${props.color}-300 text-opacity-95 mb-0 rounded`}
             style={{fontFamily: "Astronomus-Italic", padding: "-2px"}}>{Math.round(props.totalValue || props.baseValue)}</p>
          : <></>
        }
      </div>
    </div>
  );
}

export default ValueBarComponent;

