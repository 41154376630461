export enum EUnitKind {
  Mainz = 0,
  Gladius = 1,
  Billhook = 2,
  Cleaver = 3,
  Falchion = 4,
  Jumper = 5,
  Striker = 6,
  Spatha = 7,
  Phantom = 8,
  Walker = 100,
  Rifleman = 200,
  Hudson = 201,
  MiniMecha = 202,
}
