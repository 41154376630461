import React, { ReactElement } from 'react';
import { MainMenuProvider } from './parts/main-menu.provider';
import { MainMenuComponent } from './parts/main-menu.component';

export function MainMenuStandalone(): ReactElement {
  return (
    <MainMenuProvider>
      <MainMenuComponent />
    </MainMenuProvider>
  );
}
