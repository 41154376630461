export enum EWeaponKind {
  None = 2147483647,
  MountainGun = 0,
  MiniGun = 1,
  LaserBeamWeapon = 2,
  Akkad = 3, // Huge anti-tank rifle
  Mauler = 4, // Small minigun
  Organette = 5, // Big guided missile pod
  SchraegeMusik = 6, // Anti-air turret
  Autocannon = 7,

  MechCannon = 100,
  MechTeslaWeapon = 101,

  InfantryRifle = 200,
  InfantryPistol = 201,
  InfantryPowerRifle = 202,

  // Secondary weapons
  RocketPod = 300,
  HalfRocketPod = 301,
  FiftyCal = 302,
}
