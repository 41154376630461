import {
  BehaviorSubject,
  combineLatest,
  concatMap,
  debounceTime,
  delay,
  exhaustMap, filter,
  from,
  map,
  of,
  shareReplay, Subject, Subscription, take, takeUntil,
} from 'rxjs';
import {IStore} from '../../../types/interfaces/store.interface';
import {interop} from '../../../interop';
import {array$$, nullable$$} from '../../../helpers/observable.helper';
import {IWorkshopItemDetails} from '../../../types/interop/workshop-item-details.interface';
import {ILobby} from "../../../types/interop/lobby.interface";

export class MatchmakingStore implements IStore {
  setSelectedMapId(id: string): void {
    this.selectedMapIdSubject.next(id);
  }

  private readonly selectedMapIdSubject = new BehaviorSubject<string>('');
  private readonly selectedMap$ = combineLatest([
    interop.playerWorkshopMapChoices$,
    this.selectedMapIdSubject,
  ]).pipe(
    map(([workshopMapChoices, selectedMapId]) =>
      workshopMapChoices.find((_map) => _map.PublishedFileId === selectedMapId)
    ),
    shareReplay(),
    debounceTime(100)
  );

  private readonly playerMapChoices$ = combineLatest([
    interop.playerWorkshopMapChoices$,
    this.selectedMap$,
  ]).pipe(
    map(([playerMaps, ourChosenMap]) =>
      playerMaps.map((workshopMap) => {
        const result = workshopMap as any as IWorkshopItemDetails & {
          isSelected: boolean;
          votes: number;
          topVoted: boolean;
          ImageData: string;
        };
        result.isSelected = result.PublishedFileId === ourChosenMap?.PublishedFileId;
        return result;
      })
    ),
    debounceTime(100)
  );

  readonly playerMapChoices$$ = array$$(this.playerMapChoices$);

  readonly selectedMap$$ = nullable$$(this.selectedMap$);

  private readonly destructionSubject = new Subject<void>();

  constructor() {
    interop.missingImageIds$
      .pipe(
        takeUntil(this.destructionSubject),
        exhaustMap((missingImageIds) =>
          from(missingImageIds).pipe(concatMap((x) => of(x).pipe(delay(500))))
        )
      )
      .subscribe((ugcImageId) => {
        interop.fetchUgcImage(ugcImageId);
      });

    combineLatest([interop.lobby$, interop.workshopSubscriptions$])
      .pipe(
        takeUntil(this.destructionSubject),
        filter(([lobby, _]) => !!lobby),
        filter(([lobby, subscriptions]) => (lobby as ILobby).Members.length == 1 && subscriptions.length == 0),
        debounceTime(2000),
        take(1)
      )
      .subscribe(() => {
        interop.notSubscribedToWorkshopMaps();
      });
  }

  readonly reset = (): void => {
    this.destructionSubject.next();
    this.destructionSubject.complete();

    this.selectedMapIdSubject.complete();
  };
}
