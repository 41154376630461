import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  shareReplay,
  Subject,
  tap,
  throttleTime,
} from 'rxjs';
import { array$$, bool$$, nullable$$, number$$ } from './helpers/observable.helper';
import { TeamColorEnum } from './types/enums/team-color.enum';
import { ILobby } from './types/interop/lobby.interface';
import { sceneContexts } from './types/enums/context.enum';
import { IAudioSettings } from './types/interfaces/audio-settings.interface';
import { IGraphicsSettings } from './types/interfaces/graphics-settings.interface';
import { IWorkshopItemDetails } from './types/interop/workshop-item-details.interface';
import {IReplay} from "./types/interop/replay.interface";
import {EVehicleKind} from "./types/enums/vehicle-kind.enum";
import { IAgent } from './types/interfaces/agent.interface';

class Interop {
  private ready = false;
  private lobbiesSubject = new BehaviorSubject<ILobby[]>([]);
  private replaysSubject = new BehaviorSubject<IReplay[]>([]);
  private lobbySubject = new BehaviorSubject<ILobby | undefined>(undefined);
  private ownNetIdSubject = new BehaviorSubject<number | null>(null);
  private wasDisconnectedFromMatchSubject = new Subject<boolean>();
  private contextsSubject = new Subject<string[]>();

  /**
   * The workshop maps the players is subscribed to
   */
  private workshopSubscriptionsSubject = new Subject<IWorkshopItemDetails[]>();

  /**
   * The workshop map choices of all players
   */
  private playerWorkshopMapsSubject = new Subject<IWorkshopItemDetails[]>();
  private imagesSubject = new BehaviorSubject<Map<string, string>>(new Map<string, string>());
  private isEscMenuActiveSubject = new BehaviorSubject<boolean>(false);
  private agentsInAssemblySubject = new Subject<IAgent[]>();

  agentsInAssembly$ = this.agentsInAssemblySubject.asObservable();

  playerWorkshopMapChoices$ = combineLatest([
    this.playerWorkshopMapsSubject,
    this.imagesSubject,
  ]).pipe(
    map(([workshopMaps, images]) => {
      const nonce = new Map<string, number>();

      let maxVotes = 0;
      for (let i = 0; i < workshopMaps.length; i++) {
        const votes = workshopMaps.filter(
          (x) => x.PublishedFileId === workshopMaps[i].PublishedFileId
        ).length;
        if (votes > maxVotes) {
          maxVotes = votes;
        }

        const fileId = workshopMaps[i].PublishedFileId;
        nonce.set(fileId, (nonce.get(fileId) ? (nonce.get(fileId) as number) : 0) + 1);
      }

      return workshopMaps.map((workshopMap) => {
        return {
          ...workshopMap,
          ImageData: images.get(workshopMap.PreviewImageUgcHandle),
          topVoted:
            workshopMaps.filter((x) => x.PublishedFileId === workshopMap.PublishedFileId).length ===
            maxVotes,
          votes: nonce.get(workshopMap.PublishedFileId) as number,
        };
      });
    }),
    shareReplay()
  );

  workshopSubscriptions$ = combineLatest([
    this.workshopSubscriptionsSubject.pipe(
      distinctUntilChanged((a, b) => {
        return (
          a
            .map((x) => x.PublishedFileId)
            .sort()
            .join('') ===
          b
            .map((y) => y.PublishedFileId)
            .sort()
            .join('')
        );
      })
    ),
    this.imagesSubject,
  ]).pipe(
    map(([workshopMaps, images]) =>
      workshopMaps.map((workshopMap) => ({
        // TODO: Don't use map. Use for loop instead without creating a copy.
        ...workshopMap,
        ImageData: images.get(workshopMap.PreviewImageUgcHandle),
      }))
    ),
    tap(() => console.log('Workshop subscriptions changed')),
    shareReplay()
  );

  private workshopMaps$ = combineLatest([
    this.playerWorkshopMapChoices$,
    this.workshopSubscriptions$,
  ]);

  workshopSubscriptions$$ = array$$(this.workshopSubscriptions$);
  workshopMapChoices$$ = array$$(this.playerWorkshopMapChoices$);

  private graphicsSettings = new BehaviorSubject<IGraphicsSettings>({
    AntiAliasing: true,
    AmbientOcclusion: true,
    ChromaticAberration: true,
    DynamicLights: true,
    SSGI: true,
    FilmGrain: true,
    HighFidelity: true,
    SnowVFX: true,
    DistortionVFX: false,
    HighQualityTerrain: true
  });
  graphicsSettings$$ = nullable$$(this.graphicsSettings);

  private audioSettings = new BehaviorSubject<IAudioSettings>({
    InGameMusicVolume: 0,
    MainMenuMusicVolume: 0,
    MainMenuAmbienceVolume: 0,
  });
  audioSettings$$ = nullable$$(this.audioSettings);

  wasDisconnectedFromMatch$ = this.wasDisconnectedFromMatchSubject.asObservable();
  wasDisconnectedFromMatch$$ = bool$$(this.wasDisconnectedFromMatch$);

  lobby$ = this.lobbySubject.asObservable();
  lobby$$ = nullable$$(this.lobby$);

  ownNetId$ = this.ownNetIdSubject.asObservable();
  ownNetId$$ = nullable$$(this.ownNetId$);

  lobbies$ = this.lobbiesSubject.asObservable();
  lobbies$$ = nullable$$(this.lobbies$);

  replays$ = this.replaysSubject.asObservable();
  replays$$ = nullable$$(this.replays$);

  contexts$ = this.contextsSubject.asObservable();
  contexts$$ = array$$(this.contexts$);

  sceneContext$ = this.contextsSubject.pipe(
    map((els) => els.find((el) => (sceneContexts as string[]).includes(el))),
    filter((val) => !!val)
  );

  nonce(maps: IWorkshopItemDetails[], images: Map<string, string>): string[] {
    return maps
      .filter((x) => !(x as any).ImageData)
      .map((y) => y.PreviewImageUgcHandle)
      .filter((imageUgcHandle) => !images.get(imageUgcHandle));
  }

  // missingMapImages$: Observable<string[]> = combineLatest(
  //   [this.workshopMaps$.pipe(startWith([[], []])), this.imagesSubject.pipe(startWith(new Map()))]
  // ).pipe(
  //   map(([maps, images]) =>
  //     Array.from(
  //       new Set([...this.nonce(maps[0] as any, images), ...this.nonce(maps[1] as any, images)])
  //     )
  //   ),
  //   shareReplay()
  // );

  missingImageIds$: Observable<string[]> = combineLatest([
    this.workshopMaps$,
    this.imagesSubject,
  ]).pipe(
    map(([maps, images]) =>
      Array.from(
        new Set([...this.nonce(maps[0] as any, images), ...this.nonce(maps[1] as any, images)])
      )
    )
  );

  sceneContext$$ = nullable$$(this.sceneContext$);

  isEscMenuActive$ = this.isEscMenuActiveSubject.asObservable();
  isEscMenuActive$$ = bool$$(this.isEscMenuActive$);

  setAgentsInAssembly(val: IAgent[]): void {
    this.agentsInAssemblySubject.next(val);
  }

  setReady(): void {
    this.ready = true;
  }

  setContexts(contexts: string[]): void {
    this.contextsSubject.next(contexts);
  }

  setLobbyList(lobbyList: ILobby[]): void {
    this.lobbiesSubject.next(lobbyList);
  }

  setReplayList(replayList: IReplay[]): void {
    this.replaysSubject.next(replayList);
  }

  setLobbyCallback(lobby?: ILobby): void {
    this.lobbySubject.next(lobby);
  }

  setWorkshopSubscriptionsCallback(workshopItemDetails: IWorkshopItemDetails[]): void {
    this.workshopSubscriptionsSubject.next(workshopItemDetails);
  }

  setPlayerWorkshopMapsCallback(workshopItemDetails: IWorkshopItemDetails[]): void {
    this.playerWorkshopMapsSubject.next(workshopItemDetails);
  }

  setDownloadedImageCallback(key: string, value: string): void {
    this.imagesSubject.value.set(key, value);
    this.imagesSubject.next(this.imagesSubject.value);
  }

  setToggleEscMenuCallback(bool: boolean): void {
    this.isEscMenuActiveSubject.next(bool);
  }

  setOwnNetId(netId: number | null): void {
    this.ownNetIdSubject.next(netId);
  }

  setAudioSettings(val: IAudioSettings): void {
    this.audioSettings.next(val);
  }

  setGraphicsSettings(val: IGraphicsSettings): void {
    this.graphicsSettings.next(val);
  }

  nextWasDisconnected(): void {
    this.wasDisconnectedFromMatchSubject.next(true);
  }

  handleQuitClick(): void {
    if (!this.ready) {
      return;
    }
    try {
      // @ts-ignore
      handleQuitClickInterop();
    } catch (e) {
      console.warn('handleQuitClickError:', e);
    }
  }

  fetchLobbyList(): void {
    if (!this.ready) {
      return;
    }
    try {
      // @ts-ignore
      fetchLobbyListInterop();
    } catch (e) {
      console.warn('fetchLobbyList error:', e);
    }
  }

  fetchReplayList(): void {
    if (!this.ready) {
      return;
    }
    try {
      // @ts-ignore
      fetchReplayListInterop();
    } catch (e) {
      console.warn('fetchReplayList error:', e);
    }
  }

  fetchUgcImage(imageId: string): void {
    if (!this.ready) {
      return;
    }
    try {
      // @ts-ignore
      fetchUgcImageInterop(imageId);
    } catch (e) {
      console.warn('fetchLobbyList error:', e);
    }
  }

  fetchLobby(): void {
    if (!this.ready) {
      return;
    }
    // console.log('fetching lobby');
    try {
      // @ts-ignore
      fetchLobbyInterop();
    } catch (e) {
      console.warn('fetchLobby error:', e);
    }
  }

  fetchWorkshopSubscriptions(): void {
    if (!this.ready) {
      return;
    }
    // console.log('fetching lobby');
    try {
      // @ts-ignore
      fetchWorkshopSubscriptionsInterop();
    } catch (e) {
      console.warn('fetchWorkshopSubscriptions error:', e);
    }
  }

  notSubscribedToWorkshopMaps(): void {
    if (!this.ready) {
      return;
    }
    // console.log('fetching lobby');
    try {
      // @ts-ignore
      notSubscribedToWorkshopMapsInterop();
    } catch (e) {
      console.warn('fetchWorkshopSubscriptionsInterop error:', e);
    }
  }

  readyUp(value: boolean): void {
    if (!this.ready) {
      return;
    }
    console.log('readying up');
    try {
      // @ts-ignore
      readyUpInterop(value);
    } catch (e) {
      console.warn('readyUp error:', e);
    }
  }

  leaveMatch(): void {
    if (!this.ready) {
      return;
    }
    console.log('leaving match');
    try {
      // @ts-ignore
      leaveMatchInterop();
    } catch (e) {
      console.warn('leaveMatch error:', e);
    }
  }

  createMatch(matchName: string): void {
    if (!this.ready) {
      return;
    }
    console.log('creating match');
    try {
      // @ts-ignore
      createMatchInterop(matchName);
    } catch (e) {
      console.warn('createMatch error:', e);
    }
  }

  joinMatch(lobbyId: number): void {
    if (!this.ready) {
      return;
    }
    console.log('joining match');
    try {
      // @ts-ignore
      joinMatchInterop(lobbyId);
    } catch (e) {
      console.warn('joinMatch error:', e);
    }
  }

  updateLobbyVisibility(isVisible: boolean): void {
    if (!this.ready) {
      return;
    }
    console.log('setting lobby visibility to', isVisible);
    try {
      // @ts-ignore
      updateLobbyVisibilityInterop(isVisible);
    } catch (e) {
      console.warn('updateLobbyVisibility error:', e);
    }
  }

  updateLobbyName(lobbyName: string): void {
    if (!this.ready) {
      return;
    }
    console.log('updating lobby name to', lobbyName);
    try {
      // @ts-ignore
      updateLobbyNameInterop(lobbyName);
    } catch (e) {
      console.warn('updateLobbyName error:', e);
    }
  }

  fetchOwnNetId(): void {
    if (!this.ready) {
      return;
    }
    console.log('fetching own net ID');
    try {
      // @ts-ignore
      fetchOwnNetIdInterop();
    } catch (e) {
      console.warn('fetchOwnNetId error:', e);
    }
  }

  // fetchOwnLobbyId(): void {
  //   if(!this.ready) {return;}
  //   console.log('fetching own lobby ID');
  //   try {
  //     // @ts-ignore
  //     fetchOwnLobbyIdInterop();
  //   } catch (e) {
  //     console.warn('fetchLobbyNetId error:', e);
  //   }
  // }

  disconnectFromMatch(): void {
    if (!this.ready) {
      return;
    }
    console.log('disconnecting from match');
    try {
      // @ts-ignore
      disconnectFromMatchInterop();
    } catch (e) {
      console.warn('disconnectFromMatch error:', e);
    }
  }

  changeTeamColor(teamColor: TeamColorEnum): void {
    if (!this.ready) {
      return;
    }
    console.log('changing team color');
    try {
      // @ts-ignore
      changeTeamColorInterop(teamColor);
    } catch (e) {
      console.warn('changeTeamColor error:', e);
    }
  }

  kickPlayer(netId: number): void {
    if (!this.ready) {
      return;
    }
    console.log('kicking player');
    try {
      // @ts-ignore
      kickPlayerInterop(netId);
    } catch (e) {
      console.warn('kickPlayer error:', e);
    }
  }

  setSelectedWorkshopMap(publishedFileId: string): void {
    if (!this.ready) {
      return;
    }
    try {
      // @ts-ignore
      setSelectedWorkshopMapInterop(publishedFileId);
    } catch (e) {
      console.warn('setSelectedWorkshopMap error:', e);
    }
  }

  quickMatch(): void {
    if (!this.ready) {
      return;
    }
    console.log('quick matching');
    try {
      // @ts-ignore
      quickMatchInterop();
    } catch (e) {
      console.warn('quickMatch error:', e);
    }
  }

  nextVehicleHullForIndex(index: number): void {
    if (!this.ready) {
      return;
    }
    console.log(`switching to next vehicle hull`);
    try {
      // @ts-ignore
      nextVehicleHullForIndex(index);
    } catch (e) {
      console.warn('nextVehicleHullForIndex error:', e);
    }
  }

  nextPrimaryWeaponForIndex(index: number): void {
    if (!this.ready) {
      return;
    }
    console.log(`nextPrimaryWeaponForIndex`);
    try {
      // @ts-ignore
      nextPrimaryWeaponForIndex(index);
    } catch (e) {
      console.warn('nextPrimaryWeaponForIndex error:', e);
    }
  }

  previousPrimaryWeaponForIndex(index: number): void {
    if (!this.ready) {
      return;
    }
    console.log(`previousPrimaryWeaponForIndex`);
    try {
      // @ts-ignore
      previousPrimaryWeaponForIndex(index);
    } catch (e) {
      console.warn('previousPrimaryWeaponForIndex error:', e);
    }
  }

  nextSecondaryWeaponForIndex(index: number): void {
    if (!this.ready) {
      return;
    }
    console.log(`nextSecondaryWeaponForIndex`);
    try {
      // @ts-ignore
      nextSecondaryWeaponForIndex(index);
    } catch (e) {
      console.warn('nextSecondaryWeaponForIndex error:', e);
    }
  }

  previousSecondaryWeaponForIndex(index: number): void {
    if (!this.ready) {
      return;
    }
    console.log(`previousSecondaryWeaponForIndex`);
    try {
      // @ts-ignore
      previousSecondaryWeaponForIndex(index);
    } catch (e) {
      console.warn('previousSecondaryWeaponForIndex error:', e);
    }
  }

  nextSpecializationForIndex(index: number): void {
    if (!this.ready) {
      return;
    }
    console.log(`nextSpecializationForIndex`);
    try {
      // @ts-ignore
      nextSpecializationForIndex(index);
    } catch (e) {
      console.warn('nextSpecializationForIndex error:', e);
    }
  }

  previousSpecializationForIndex(index: number): void {
    if (!this.ready) {
      return;
    }
    console.log(`previousSpecializationForIndex`);
    try {
      // @ts-ignore
      previousSpecializationForIndex(index);
    } catch (e) {
      console.warn('previousSpecializationForIndex error:', e);
    }
  }

  nextHoverpadsForIndex(index: number): void {
    if (!this.ready) {
      return;
    }
    console.log(`nextHoverpadsForIndex`);
    try {
      // @ts-ignore
      nextHoverpadsForIndex(index);
    } catch (e) {
      console.warn('nextHoverpadsForIndex error:', e);
    }
  }

  previousHoverpadsForIndex(index: number): void {
    if (!this.ready) {
      return;
    }
    console.log(`previousHoverpadsForIndex`);
    try {
      // @ts-ignore
      previousHoverpadsForIndex(index);
    } catch (e) {
      console.warn('previousHoverpadsForIndex error:', e);
    }
  }

  previousVehicleHullForIndex(index: number): void {
    if (!this.ready) {
      return;
    }
    console.log(`switching to previous vehicle hull`);
    try {
      // @ts-ignore
      previousVehicleHullForIndex(index);
    } catch (e) {
      console.warn('previousVehicleHullForIndex error:', e);
    }
  }

  addVehicleToAssembly(_vehicleKind: EVehicleKind): void {
    if (!this.ready) {
      return;
    }
    console.log('adding vehicle to assembly');
    try {
      // @ts-ignore
      addVehicleToAssemblyInterop(_vehicleKind);
    } catch (e) {
      console.warn('addVehicleToAssembly error:', e);
    }
  }

  /**
   * Updates the selected agent for the Unity backend.
   * Does not affect frontend.
   */
  setSelectedAgentInAssemblyByIndex(_val: number): void {
    if (!this.ready) {
      return;
    }
    console.log('setting selected agent by index');
    try {
      // @ts-ignore
      setSelectedAgentInAssemblyByIndex(_val);
    } catch (e) {
      console.warn('setSelectedAgentInAssemblyByIndex error:', e);
    }
  }

  assemblyScreen(_val: boolean): void {
    if (!this.ready) {
      return;
    }
    console.log('calling assembly screen function');
    try {
      // @ts-ignore
      assemblyScreenInterop(_val);
    } catch (e) {
      console.warn('assemblyScreenInterop error:', e);
    }
  }

  singleplayerFn(): void {
    if (!this.ready) {
      return;
    }
    console.log('calling singleplayer function');
    try {
      // @ts-ignore
      singleplayerInterop();
    } catch (e) {
      console.warn('singleplayerInterop error:', e);
    }
  }

  loadReplaySceneFn(replayName: string): void {
    if (!this.ready) {
      return;
    }
    console.log('calling load replay scene function');
    try {
      // @ts-ignore
      loadReplaySceneInterop(replayName);
    } catch (e) {
      console.warn('loadReplaySceneInterop error:', e);
    }
  }

  deleteReplayFn(replayName: string): void {
    if (!this.ready) {
      return;
    }
    console.log('calling delete replay function');
    try {
      // @ts-ignore
      deleteReplayInterop(replayName);
    } catch (e) {
      console.warn('deleteReplayFn error:', e);
    }
  }

  loadRteSceneFn(): void {
    if (!this.ready) {
      return;
    }
    console.log('calling load rte scene function');
    try {
      // @ts-ignore
      loadRteSceneInterop();
    } catch (e) {
      console.warn('loadRteSceneInterop error:', e);
    }
  }

  loadMainMenuFn(): void {
    if (!this.ready) {
      return;
    }
    console.log('calling load main menu function');
    try {
      // @ts-ignore
      loadMainMenuInterop();
    } catch (e) {
      console.warn('loadMenuInterop error:', e);
    }
  }

  changeGraphicsSettings(val: IGraphicsSettings): void {
    if (!this.ready) {
      return;
    }
    console.log('calling change graphics settings interop function');
    try {
      // @ts-ignore
      changeGraphicsSettingsInterop(JSON.stringify(val));
    } catch (e) {
      console.warn('interop changeGraphicsSettings error:', e);
    }
  }

  changeAudioSettingsSubject = new Subject<IAudioSettings>();

  changeAudioSettings(val: IAudioSettings): void {
    this.changeAudioSettingsSubject.next(val);
  }

  constructor() {
    this.changeAudioSettingsSubject.pipe(throttleTime(20)).subscribe((val) => {
      if (!this.ready) {
        return;
      }
      console.log('calling change audio settings interop function');
      try {
        // @ts-ignore
        changeAudioSettingsInterop(JSON.stringify(val));
      } catch (e) {
        console.warn('interop changeAudioSettings error:', e);
      }
    });
  }
}

export const interop = new Interop();
