import React from "react";
import {EDamageKind} from "../types/enums/damage-kind.enum";
import {variousHelper} from "../helpers/various.helper";
import {ESpecialization} from "../types/enums/specialization.enum";

function SpecializationIcon(props: { specialization: ESpecialization, paddingTop: number }) {
  return <div className={`h-5 w-5 ${variousHelper.iconFromSpecialization(props.specialization)}-icon-mask-image`} style={{
    backgroundColor: `hsl(${variousHelper.hueShiftFromSpecialization(props.specialization)} 100% 50%)`,
    paddingTop: `${props.paddingTop}rem`
  }}>
    {variousHelper.iconFromSpecialization(props.specialization)}
  </div>;
}

export default SpecializationIcon;
