import {ReactElement, ReactNode} from "react";

function AssemblyContainer({children}: {children: ReactNode}): ReactElement {
  return (
    <div className="col-span-12 grid grid-cols-12 gap-0 p-0">
      <div className="col-span-12 grid grid-cols-12 gap-0 border-cyan-300 border-4 border-opacity-100">
        <div className="col-span-12 grid grid-cols-12 gap-0 w-full">
          {children}
        </div>
      </div>
    </div>
  );
}

export default AssemblyContainer;
