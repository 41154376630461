import React, { useState, useRef, FunctionComponent, ReactNode, ReactElement } from 'react';

type DropdownProps<T, K extends keyof T, W extends keyof T> = {
  selectedOption?: T | null;
  options: T[];
  idKey: K;
  titleKey: W & (T[W] extends string ? W : never);
  onSelect: (k: T[K]) => void;
  Container: React.FC<{ children: ReactNode }>;
  scrollable?: boolean;
  itemName?: string;
  cta?: string;
};

function Dropdown<T, K extends keyof T, W extends keyof T>({
  selectedOption,
  options,
  onSelect,
  idKey,
  titleKey,
  Container,
  scrollable = false,
  itemName = 'option',
  cta,
}: DropdownProps<T, K, W>): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <div className="relative">
      <button
        ref={buttonRef}
        className="bg-black bg-opacity-50 text-gray-800 py-2 px-4 rounded-lg"
        onClick={() => setIsOpen(!isOpen)}>
        <Container>
          {options.length > 0
            ? selectedOption
              ? selectedOption[titleKey]
              : `Please select ${itemName}`
            : cta || `No ${itemName}s available`}
        </Container>
      </button>
      {isOpen && (
        <div
          className="absolute left-0 z-10 bg-black bg-opacity-50 rounded-lg shadow-md"
          style={{ width: buttonRef?.current?.offsetWidth }}>
          <div className={scrollable ? 'overflow-y-scroll h-48' : ''}>
            {options.map((option) => (
              <button
                key={option[idKey] as any}
                className="block w-full p-2 text-gray-800 hover:bg-gray-500 hover:bg-opacity-50 rounded-lg"
                onClick={() => {
                  setIsOpen(false);
                  onSelect(option[idKey]);
                }}>
                <Container>{option[titleKey]}</Container>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Dropdown;
