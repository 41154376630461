import {
  BehaviorSubject,
  combineLatest, debounceTime,
  distinctUntilChanged,
  map, Observable,
  of,
  pairwise,
  Subject,
  take,
  takeUntil,
  tap
} from 'rxjs';
import {v4 as uuid} from 'uuid';
import {array$$, nullable$$, number$$} from '../../../helpers/observable.helper';
import {IStore} from '../../../types/interfaces/store.interface';
import {interop} from '../../../interop';
import {l$} from "../../../locale";
import moment from "moment";
import _ from "lodash";
import {useObservable} from "../../../helpers/useObservable";
import {fileHelper} from "../../../helpers/file.helper";
import {IAgent} from "../../../types/interfaces/agent.interface";

export class AssemblyStore implements IStore {
  private readonly destructionSubject = new Subject<void>();
  private selectedAgentIndexInAssemblySubject = new BehaviorSubject<number>(0);
  private selectedAgentIndexInAssembly$ = this.selectedAgentIndexInAssemblySubject.pipe(distinctUntilChanged());

  private agents$ = combineLatest([interop.agentsInAssembly$, this.selectedAgentIndexInAssemblySubject])
    .pipe(
      map(([agents, selectedAgentIndex]) =>
        agents.map((agent, i) => (
            {
              ...agent,
              IsSelected: i == selectedAgentIndex
            } as IAgent & { IsSelected: boolean }
          )
        )
      )
    );
  agents$$ = array$$(this.agents$);

  selectedAgentIndex$ = this.agents$.pipe(map(units => units.findIndex(unit => unit.IsSelected)));
  selectedAgentIndex$$ = number$$(this.selectedAgentIndex$);

  selectedAgent$ = combineLatest([this.selectedAgentIndex$, this.agents$]).pipe(map(([selectedAgentIndex, agents]) => agents[selectedAgentIndex]));
  selectedAgent$$ = nullable$$(this.selectedAgent$);

  // selectedAgent = combineLatest([this.selectedAgentIndex$, this.agents$]).pipe(map(([selectedAgentIndex, agents]) => selectedAgentIndex < 0 ? undefined : agents[selectedAgentIndex]))

  public setSelectedAgentIndex(val: number) {
    this.selectedAgentIndexInAssemblySubject.next(val);
  }

  constructor() {
    this.selectedAgentIndexInAssembly$.pipe(takeUntil(this.destructionSubject)).subscribe(x => {
      interop.setSelectedAgentInAssemblyByIndex(x);
    });

    interop.agentsInAssembly$.pipe(takeUntil(this.destructionSubject), pairwise())
      .subscribe(([a, b]) => {
        if (b.length > a.length) {
          this.selectedAgentIndexInAssemblySubject.next(Math.max(b.length - 1, 0));
        }
      });

    combineLatest([interop.agentsInAssembly$, this.selectedAgentIndexInAssembly$]).pipe(takeUntil(this.destructionSubject), debounceTime(100))
      .subscribe(([agents, selectedAgentIndex]) => {
        if (selectedAgentIndex > agents.length - 1) {
          this.selectedAgentIndexInAssemblySubject.next(Math.max(agents.length - 1, 0));
        }
      });
  }

  reset = (): void => {
    this.destructionSubject.next();
    this.destructionSubject.complete();
  };
}
