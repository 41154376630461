import './App.css';
import {ArwesThemeProvider, StylesBaseline} from '@arwes/core';
import {BleepsProvider} from '@arwes/sounds';
import {Routes, Route, useNavigate} from 'react-router-dom';
import {useObservableState} from 'observable-hooks';
import {createTheme} from '@arwes/design';
import {AnimatorGeneralProvider} from '@arwes/animation';
import React, {ReactElement, useEffect, useState} from 'react';
import {environment$} from './environment';
import TypesafeI18n from './i18n/i18n-react';
import {
  animator$,
  audioSettings$,
  bleepsSettings$,
  playersSettings$,
} from './applicationSettings';
import {MainMenuStandalone} from './pages/main-menu/main-menu.standalone';
import {useObservable} from './helpers/useObservable';
import {interop} from './interop';
import {EContext} from './types/enums/context.enum';
import {InMatchStandalone} from './pages/in-match/in-match.standalone';
import {SettingsStandalone} from './pages/settings/settings.standalone';
import {WidgetsStandalone} from './pages/widgets/widgets.standalone';
import {MatchmakingStandalone} from './pages/matchmaking/matchmaking.standalone';
import {RteStandalone} from './pages/rte/rte.standalone';
import {ReplaysStandalone} from "./pages/replays/replays.standalone";
import {AssemblyStandalone} from "./pages/assembly/assembly.standalone";
import {VolumeMute, VolumeUp} from "@material-ui/icons";

function App(): ReactElement {
  const env = useObservableState(environment$, undefined);
  const audioSettings = useObservableState(audioSettings$, undefined);
  const playersSettings = useObservableState(playersSettings$, undefined);
  const bleepsSettings = useObservableState(bleepsSettings$, undefined);
  const animator = useObservableState(animator$, undefined);

  const navigate = useNavigate();
  const sceneContext = useObservable(interop.sceneContext$$);

  const [soundEnabled, setSoundEnabled] = useState(false);
  let audioSource = (document.getElementById('audio') as any);

  function toggleSound(): void {
    if (!soundEnabled) {
      audioSource.play();
      audioSource.volume = 0.5;
      setSoundEnabled(true);
    } else {
      audioSource.pause();
      setSoundEnabled(false);
    }
  }

  useEffect(() => {
    if (!sceneContext) {
      return;
    }

    switch (sceneContext) {
      case EContext.MainMenu: {
        console.log('navigating to /');
        navigate('/');
        break;
      }
      case EContext.InMatch: {
        console.log('navigating to /in-match');
        navigate('/in-match');
        break;
      }
      case EContext.ReplayScene: {
        console.log('navigating to /replay-scene');
        navigate('/replay-scene');
        break;
      }
      case EContext.RteScene: {
        console.log('navigating to /rte');
        navigate('/rte');
        break;
      }
      case EContext.Assembly: {
        console.log('navigating to /assembly');
        navigate('/assembly');
        break;
      }
      default: {
        console.error('sceneContext switch case missing!');
      }
    }
  }, [sceneContext]);

  const theme = createTheme({
    palette: {tonalOffset: 0.18, primary: {main: '#01d9d9'}, secondary: {main: '#00b2e8'}},
  });

  const styles: {
    html: { background: string } | Record<string, never>;
    body: { letterSpacing?: string; fontFamily: string; background?: string };
  } = {
    body: {
      fontFamily: '"Bios Bold", sans',
    },
    html: env === 'unity' ? {background: 'transparent'} : {},
  };

  if (env === 'unity') {
    styles.body.background = 'transparent';
  }

  return env && audioSettings && playersSettings && bleepsSettings && animator ? (
    <div>
      <div onClick={toggleSound} style={{position: "absolute"}}>
        {soundEnabled ? <VolumeUp/> : <VolumeMute/>}
      </div>
      <TypesafeI18n locale="en">
        <ArwesThemeProvider themeSettings={{palette: theme.palette}}>
          <StylesBaseline styles={styles}/>
          {soundEnabled ?
            <BleepsProvider
              audioSettings={audioSettings}
              playersSettings={playersSettings}
              bleepsSettings={bleepsSettings}>
              <AnimatorGeneralProvider animator={animator}>
                <Routes>
                  <Route
                    path="/*"
                    element={
                      <WidgetsStandalone>
                        <Routes>
                          <Route path="" element={<MainMenuStandalone/>}/>
                          <Route path="assembly" element={<AssemblyStandalone/>}/>
                          <Route path="settings" element={<SettingsStandalone/>}/>
                          <Route path="matchmaking" element={<MatchmakingStandalone/>}/>
                          <Route path="replays" element={<ReplaysStandalone/>}/>
                        </Routes>
                      </WidgetsStandalone>
                    }
                  />
                  <Route path="/in-match" element={<InMatchStandalone/>}/>
                  <Route path="/rte" element={<RteStandalone/>}/>
                </Routes>
              </AnimatorGeneralProvider>
            </BleepsProvider>
            :
            <AnimatorGeneralProvider animator={animator}>
              <Routes>
                <Route
                  path="/*"
                  element={
                    <WidgetsStandalone>
                      <Routes>
                        <Route path="" element={<MainMenuStandalone/>}/>
                        <Route path="assembly" element={<AssemblyStandalone/>}/>
                        <Route path="settings" element={<SettingsStandalone/>}/>
                        <Route path="matchmaking" element={<MatchmakingStandalone/>}/>
                        <Route path="replays" element={<ReplaysStandalone/>}/>
                      </Routes>
                    </WidgetsStandalone>
                  }
                />
                <Route path="/in-match" element={<InMatchStandalone/>}/>
                <Route path="/rte" element={<RteStandalone/>}/>
              </Routes>
            </AnimatorGeneralProvider>
          }
        </ArwesThemeProvider>
      </TypesafeI18n>
    </div>
  ) : (
    <></>
  );
}

export default App;
