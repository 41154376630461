import type { Translation } from '../i18n-types';

const de: Translation = {
  Exit: 'Verlassen',
  Filter: 'Filter',
  Chat: 'Chat',
  Servers: 'Server',
  Server: 'Server',
  ServerName: 'Server Name',
  GameMode: 'Spielmodus',
  Players: 'Spieler',
  Ping: 'Ping',
  Tutorial: 'Tutorial',
  Singleplayer: 'Einspieler',
  Multiplayer: 'Mehrspieler',
  Profile: 'Profil',
  Options: 'Optionen',
  Quit: 'Beenden',
  Replays: 'Aufzeichnungen',
  Credits: 'Credits',
  CreateNewMatch: 'Neues Spiel',
  CreateGame: 'Neues Spiel',
  OpenGames: 'Offene Spiele',
  NewGame: 'Neues Spiel',
  LoadMatch: 'Spiel Laden',
  Close: 'Schließen',
  GoBack: 'Zurück',
  Cancel: 'Abbrechen',
  Maps: 'Karten',
  SelectedMap: 'Gewählte Karte',
  LetOtherPlayersJoin: 'Andere Spieler Erlauben',
  LetMorePlayersJoin: 'Mehr Spieler Erlauben',
  SingleplayerGame: 'Einzelspieler',
  AllowMorePlayersToJoinYourGame: 'Anderen Spielern das Beitreten erlauben',
  Match: 'Partie',
  NewMatch: 'Neue Partie'
};

export default de;
