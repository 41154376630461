import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { switchLocale } from './locale';
import { Locales } from './i18n/i18n-types';
import { interop } from './interop';
import { ILobby } from './types/interop/lobby.interface';
import { IWorkshopItemDetails } from './types/interop/workshop-item-details.interface';
import { IKeyValue } from './types/interop/key-value.interface';
import {IReplay} from "./types/interop/replay.interface";

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

function setLocale(locale: Locales): void {
  switchLocale(locale);
}

function ready(): void {
  interop.setReady();
}

function fetchLobbyListCallback(lobbyList: string): void {
  interop.setLobbyList(JSON.parse(lobbyList) as ILobby[]);
}

function fetchReplayListCallback(replayList: string): void {
  interop.setReplayList(JSON.parse(replayList) as IReplay[]);
}

function lobbyUpdateCallback(lobby: string): void {
  interop.setLobbyCallback(lobby === 'undefined' ? undefined : JSON.parse(lobby));
}

function fetchWorkshopSubscriptionsCallback(workshopItemDetails: string): void {
  interop.setWorkshopSubscriptionsCallback(JSON.parse(workshopItemDetails));
}

function fetchPlayersMapChoicesCallback(workshopItemDetails: string): void {
  interop.setPlayerWorkshopMapsCallback(JSON.parse(workshopItemDetails));
}

function downloadedImageCallback(image: string): void {
  const keyValue: IKeyValue<string, string> = JSON.parse(image);
  interop.setDownloadedImageCallback(keyValue.Key, keyValue.Value);
}

function fetchOwnNetIdCallback(netId?: number): void {
  console.log('fetch own net id success: ' + !!netId);

  interop.setOwnNetId(netId || null);
}

function wasDisconnectedFromMatchCallback(): void {
  interop.nextWasDisconnected();
}

function contextsChangedCallback(contexts: string): void {
  interop.setContexts(JSON.parse(contexts));
}

function audioSettingsCallback(val: string): void {
  interop.setAudioSettings(JSON.parse(val));
}

function graphicsSettingsCallback(val: string): void {
  interop.setGraphicsSettings(JSON.parse(val));
}

function toggleEscMenuCallback(bool: boolean): void {
  // console.log('ecs menu callback', bool);
  interop.setToggleEscMenuCallback(bool);
}

function agentsInAssemblyCallback(agents: string): void {
  interop.setAgentsInAssembly(JSON.parse(agents));
}

(window as any).setLocale = setLocale;
(window as any).ready = ready;
(window as any).fetchLobbyListCallback = fetchLobbyListCallback;
(window as any).fetchReplayListCallback = fetchReplayListCallback;
(window as any).lobbyUpdateCallback = lobbyUpdateCallback;
(window as any).fetchOwnNetIdCallback = fetchOwnNetIdCallback;
(window as any).wasDisconnectedFromMatchCallback = wasDisconnectedFromMatchCallback;
(window as any).contextsChangedCallback = contextsChangedCallback;
(window as any).audioSettingsCallback = audioSettingsCallback;
(window as any).graphicsSettingsCallback = graphicsSettingsCallback;
(window as any).toggleEscMenuCallback = toggleEscMenuCallback;
(window as any).fetchWorkshopSubscriptionsCallback = fetchWorkshopSubscriptionsCallback;
(window as any).fetchPlayersMapChoicesCallback = fetchPlayersMapChoicesCallback;
(window as any).downloadedImageCallback = downloadedImageCallback;
(window as any).agentsInAssemblyCallback = agentsInAssemblyCallback;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
