import { BehaviorSubject, combineLatest, map, Observable, of } from 'rxjs';
import { BleepsAudioSettings, BleepsPlayersSettings, BleepsSettings } from '@arwes/sounds';
import { AnimatorSettings } from '@arwes/animation';
import { fileHelper } from './helpers/file.helper';

const audioVolumeSubject = new BehaviorSubject<number>(0);

export const audioSettings$: Observable<BleepsAudioSettings> = audioVolumeSubject.pipe(
  map((volume) => ({
    common: {
      volume,
    },
  }))
);

export const animator$: Observable<AnimatorSettings> = of({
  duration: {
    enter: 100,
    exit: 100,
    stagger: 15,
  },
});

const clickAudioFilePath$ = fileHelper.soundFilePath$('Click1', 'wav');
const assembleAudioFilePath$ = fileHelper.soundFilePath$('Plop End', 'wav');
const hoverAudioFilePath$ = fileHelper.soundFilePath$('Hover', 'wav');

const typeAudioFilePaths$ = [
  'typewriter',
  ...Array(29).map((index) => 'typewriter (' + index + 1 + ')'),
].map((filename) => fileHelper.soundFilePath$('typewriter/' + filename));

export const playersSettings$: Observable<BleepsPlayersSettings> = combineLatest([
  clickAudioFilePath$,
  assembleAudioFilePath$,
  hoverAudioFilePath$,
  ...typeAudioFilePaths$,
]).pipe(
  map(([clickAudioFilePath, assembleAudioFilePath, hoverAudioFilePath, ...typeAudioFilePaths]) => ({
    // object: { src: [SOUND_OBJECT_URL] },
    click: {
      // On user click
      src: [clickAudioFilePath],
      volume: 0.7,
    },
    type: {
      // Text typing loop sound
      src: typeAudioFilePaths,
      loop: true,
    },
    readout: {
      // On text appearing / erasing
      src: [assembleAudioFilePath],
      volume: 0.01,
      loop: true,
    },
    assemble: {
      src: [assembleAudioFilePath],
      volume: 0.01,
      loop: true,
    },
    hover: {
      src: [hoverAudioFilePath],
    },
  }))
);

export const bleepsSettings$: Observable<BleepsSettings> = of({
  // object: { player: 'object' },
  click: {
    player: 'click',
  },
  typing: { player: 'type' },
  readout: { player: 'readout' },
  assemble: { player: 'assemble' },
  hover: { player: 'hover' },
});
